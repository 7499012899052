<template>
    <v-form>
        <v-card outlined>
            <v-responsive :aspect-ratio="16/9">
                <div style="padding-top:3%">
                    <v-row class="mt-3">
                        <v-col cols="1"></v-col>
                        <v-col cols="12" md="6">
                            <v-col cols="12">
                                <v-row class="py-0">
                                    <v-col class="py-0" cols="4" md="3">
                                        <v-card-text class="py-0" outlined>
                                        ISBN
                                        </v-card-text>
                                    </v-col>
                                    <v-col class="py-0">
                                        <v-card-text 
                                            id="editMyBookIsbnId"
                                            class="py-0" outlined>
                                            {{book.publishedBook.isbn}}
                                        </v-card-text>
                                    </v-col>
                                </v-row>
                                <v-row class="py-0">
                                    <v-col class="py-0" cols="4" md="3">
                                        <v-card-text class="py-0" outlined>
                                        Titel
                                        </v-card-text>
                                    </v-col>
                                    <v-col class="py-0">
                                        <v-card-text
                                            id="editMyBookTitleId" 
                                            class="py-0" 
                                            outlined>
                                            {{publishedBook.title}}
                                        </v-card-text>
                                    </v-col>
                                </v-row>
                               <v-row class="py-0">
                                    <v-col cols="4" md="3" class="py-0">
                                        <v-card-text class="py-0" outlined>
                                        Auteur
                                        </v-card-text>
                                    </v-col>
                                    <v-col class="py-0">
                                        <v-card-text 
                                            id="editMyBookAuthorId" 
                                            class="py-0" 
                                            outlined>
                                            {{publishedBook.author}}
                                        </v-card-text>
                                    </v-col>
                                </v-row>  
                                <v-row class="pt-0">
                                    <v-col cols="4" md="3" class="pt-0">
                                        <v-card-text class="pt-0" outlined>
                                        Druk
                                        </v-card-text>
                                    </v-col>
                                    <v-col class="pt-0">
                                        <v-card-text
                                            id="editMyBookEditionId" 
                                            class="pt-0" 
                                            outlined>
                                            {{publishedBook.edition}}
                                        </v-card-text>
                                    </v-col>
                                </v-row>  
                                <v-row class="py-0">
                                    <v-col class="py-0">
                                        <v-text-field
                                            id="editMyBookPriceId" 
                                            v-model="price" 
                                            outlined 
                                            dense 
                                            placeholder="bijv. 23,99"
                                            label="Prijs in €">
                                        </v-text-field>
                                    </v-col>
                                </v-row>  
                                <v-row class="mt-0">  
                                    <v-col>
                                        <v-select
                                            id="editMyBookSellOptionId"  
                                            :items="sellOptions"
                                            v-model="bookState"
                                            :item-text="'value'"
                                            :item-value="'id'"
                                            label="Verkopen"
                                            dense
                                            outlined>
                                        </v-select>
                                    </v-col>
                                    <v-col>
                                        <v-menu
                                            v-if="bookState == 2"
                                            v-model="menuReadyForSaleDate"
                                            :close-on-content-click="true"
                                            transition="scale-transition"
                                            offset-y
                                            min-width="auto">
                                            <template v-slot:activator="{ on }">
                                                <v-text-field
                                                    v-model="book.readyForSaleDate"
                                                    label="Vanaf datum:"
                                                    prepend-icon="mdi-calendar"
                                                    readonly
                                                    outlined
                                                    v-on="on"
                                                    dense
                                                ></v-text-field>
                                            </template>
                                            <v-date-picker 
                                                v-model="book.readyForSaleDate" 
                                                no-title 
                                                scrollable
                                                dense
                                                @input="menuReadyForSaleDate = false">
                                            </v-date-picker>
                                        </v-menu>
                                    </v-col>
                                </v-row>
                                <v-select
                                    id="editMyBookConditionId"  
                                    return-object
                                    :items="conditions"
                                    v-model="book.condition"
                                    :item-text="'option'"
                                    :item-value="'id'"
                                    dense
                                    label="Staat" outlined>
                                </v-select>   
                                <v-textarea
                                    id="editMyBookOwnersNoteId"  
                                    outlined
                                    v-model="book.ownersNote"
                                    name="input-7-4"
                                    label="Omschrijving">
                                </v-textarea>
                                <v-row>
                                    <v-col>
                                        <v-btn
                                            id="editMyBookEditButtonId"  
                                            color="primary"
                                            :disabled="!formValid"
                                            :loading="loading"
                                            @click="editBook">
                                            WIJZIGEN
                                        </v-btn>
                                    </v-col>
                                    <v-col>
                                       <v-btn
                                            id="editMyBookCancelButtonId"
                                            color="primary" 
                                            text outlined 
                                            @click="cancelEdit">
                                            ANNULEREN
                                        </v-btn>
                                    </v-col>
                                </v-row>
                                 <v-row>
                                    <v-col 
                                        class="px-0 mx-0" 
                                        cols="2" 
                                        md="12"></v-col>
                                    <v-col>
                                    <h4 
                                        class="red--text ml-3" 
                                        v-if="errorMessageUpdateBook">{{errorMessageUpdateBook}}
                                    </h4>
                                    </v-col>
                                </v-row>
                            </v-col>
                        </v-col>
                        <v-col cols="12" md="4" class="mt-3">
                            <v-card outlined>
                                <v-col cols="12">
                                    <v-col cols="12">
                                        <vue-upload-multiple-image
                                                idUpload="image-step2"
                                                @upload-success="uploadImageSuccess"
                                                @before-remove="beforeRemove"
                                                @edit-image="editImage"
                                                :data-images="images"
                                                dragText="Niewe foto"
                                                browseText="Add"
                                                primaryText="Hoofdfoto"
                                                @mark-is-primary="markAsPrimary"
                                                markIsPrimaryText="Instellen als hoofdfoto"
                                                popupText="Afbeeldingen van je boek">
                                        </vue-upload-multiple-image>
                                        <v-card-text>
                                            <h4>{{publishedBook.title}}</h4>
                                        </v-card-text>
                                    </v-col>
                                </v-col>
                            </v-card>
                        </v-col>
                    </v-row>
                </div>
            </v-responsive>
        </v-card>
    </v-form>
</template>

<script>
import BookService from "../services/BookService"
import VueUploadMultipleImage from 'vue-upload-multiple-image'
import { mapGetters } from 'vuex'
import axios from 'axios'

export default {
    props:['bookToEdit'],
    data () {
      return {
        publishedBook: {},
        book: {},
        isbn: '',
        images: [],
        loading:false,
        errorMessageUpdateBook: null,
        conditions: [
            { id: 3, option: 'Nieuw' },
            { id: 0, option: 'Zo goed als nieuw' },
            { id: 1, option: 'Goed' },
            { id: 2, option: 'Redelijk' },
        ],
        deliveryOptions: [
            { id: 0, option: 'Verzenden' },
        ],
        sellOptions: [
            { id: 0, value: 'Ja' },
            { id: 1, value: 'Nee' },
            { id: 2, value: 'Vanaf datum:' },
        ],
        bookState: 0,
        menuAvailableFrom: false,
        menuReadyForSaleDate: false,
        price:0.0,
        rules: {
          required: value => !!value || 'Required.',
        },
      }
    },
    created() {
        this.book = this.bookToEdit;
        this.publishedBook = this.book.publishedBook;
        this.bookState = this.bookToEdit.sellOption;
        this.book.deliveryOption =0;
        this.book.readyForSaleDate = this.bookToEdit.offeredSince;
        this.price = parseFloat(this.bookToEdit.price).toFixed(2);

        if(this.book.readyForSaleDate){
            this.book.readyForSaleDate = this.book.readyForSaleDate.replace('T00:00:00','');
        }

        this.images = []; 

        this.book.images.forEach(async (image) => {
            let file = await this.getImageBlob(image.fileNameFullPath);
            await this.createImage(file, image);
        });
    },
    watch:{
        isbn:function(val){
            if(this.bookToEdit){
                this.findBookByIsbn(val);
            }
        },
        bookState:function(val){
            this.book.sellOption = val;
        },

        price:function(val){
            this.book.price = val;
        },
    },
    computed: {
        formValid() {
            let isReadyForSaleDateValid = false;
            if(this.book.sellOption== 2 && this.book.readyForSaleDate){
                isReadyForSaleDateValid = true;
            } 

            if(this.book.sellOption != 2 ) {
                isReadyForSaleDateValid = true;
            }

            return (this.book.condition != null&&
                isReadyForSaleDateValid &&
                this.book.deliveryOption != null &&
                this.bookState != null);
        },
    },
    methods: {
        ...mapGetters(['getAccessToken']),
        async uploadImageSuccess(formData, index, fileList) {
            this.book.listUpdatedImages = [];
            this.book.listUpdatedImages = fileList;
            this.images = fileList;
            var image = fileList[index];
            await this.addImageOfBook(this.book.id, image.path);   
        },
        createImage (file, image) {
            if (this.disabled) return
            let reader = new FileReader()
            let formData = new FormData()
            formData.append('file', file)
            reader.onload = async (e) => {
                let dataURI = e.target.result
                if (dataURI) {
                if (!this.images.length) {
                    this.images.push({ name: file.name, path: dataURI, highlight: 1, default: 1, id:image.id })
                    this.currentIndexImage = 0
                } else {
                    this.images.push({ name: file.name, path: dataURI, highlight: 0, default: 0, id:image.id })
                }
                //this.$emit('upload-success', formData, this.images.length - 1, this.images)
                }
            }
            reader.readAsDataURL(file)
        },
        async getImageBlob(url){
             let data; 
             
            await axios({
                method: 'get',
                headers: { 
                // 'x-apikey': '59a7ad19f5a9fa0808f11931',
                'Access-Control-Allow-Origin' : '*',
                'Access-Control-Allow-Methods':'GET',
                },
                url: url, // blob url eg. blob:http://127.0.0.1:8000/e89c5d87-a634-4540-974c-30dc476825cc
                responseType: 'blob'
            }).then(function(response){
                data = response.data;
            });

            return data;
        },
        async beforeRemove (index, done, fileList) {
            this.book.listUpdatedImages = [];
            this.images = fileList;
            var image = fileList[index];

            await this.removeImageOfBook(this.book.id, image.id);

            done()
            this.book.listUpdatedImages = fileList;
            this.book.imageChanged = true;
        },
        editImage (formData, index, fileList) {
            this.book.listUpdatedImages = [];
            this.book.imageChanged = true;
            this.images = fileList;
            this.book.listUpdatedImages = fileList;
        },
        markAsPrimary(index, fileList) {
            this.images = fileList;
            this.book.imageChanged = true;
            this.book.listUpdatedImages = fileList;
        },     
        cancelEdit(){
            this.$router.push('MyBooks');
        }, 
        async addImageOfBook(bookId, imageData){
            await BookService.addImage(this.getAccessToken(), bookId, imageData);
        },
        async removeImageOfBook(bookId, imageId){
           await BookService.removeImage(this.getAccessToken(), bookId, imageId);
        },
        async editBook() {
            try{
                this.errorMessageUpdateBook = null;
                this.loading = true;
                this.book.condition = this.book.condition.id;
                var book = await BookService.editBook(this.getAccessToken(), this.book);
                this.$router.push('MyBooks');
            }
            catch{
                this.errorMessageUpdateBook = 'Er is een fout opgetreden.'
            }

            this.loading = false;
        }
    },
    components: {
        VueUploadMultipleImage
    }
  }
</script>