<template>
    <v-container>
        <v-row>
            <v-col cols="3" class="hidden-sm-and-down">
                <UserMenuCard></UserMenuCard>
            </v-col>
            <v-col>
              <template>
                    <v-form>
                    <v-card outlined>
                        <v-responsive :aspect-ratio="16/9">
                            <div style="padding-top:3%">
                                <v-responsive :aspect-ratio="16/9">
                                    <v-row>
                                        <v-col cols="1"></v-col>
                                            <v-col cols="10" md="8">
                                                <v-text-field
                                                    v-model="studentBook.id"
                                                    v-show="false">
                                                </v-text-field>
                                                <v-text-field
                                                    v-model="studentBook.studentId"
                                                    v-show="false">
                                                </v-text-field>

                                                 <vue-upload-multiple-image
                                                    @upload-success="uploadImageSuccess"
                                                    @before-remove="beforeRemove"
                                                    @edit-image="editImage"
                                                    :data-images="images"
                                                    dragText="Niewe foto"
                                                    browseText="Add"
                                                    primaryText="Hoofdfoto"
                                                    markIsPrimaryText="Instellen als hoofdfoto"
                                                    popupText="Afbeeldingen van je boek"
                                                    @mark-is-primary="markAsPrimary">
                                                </vue-upload-multiple-image>
                                                <br/>
                                                <v-text-field label="ISBN"
                                                    placeholder="ISBN"
                                                    v-model="studentBook.isbn" 
                                                    :rules="[rules.required]"
                                                    type="number"
                                                    outlined>
                                                </v-text-field>
                                                <v-text-field label="Titel"
                                                    v-model="studentBook.title"
                                                    placeholder="Titel"
                                                    :rules="[rules.required]"
                                                    outlined>
                                                </v-text-field>  
                                                <v-text-field label="Subtitle"
                                                    v-model="studentBook.subtitle"
                                                    placeholder="Subtitle"
                                                    outlined>
                                                </v-text-field>    
                                                <v-text-field label="Auteur"
                                                    v-model="studentBook.author"
                                                    placeholder="Auteur"
                                                    outlined>
                                                </v-text-field>  
                                                <v-text-field label="Editie"
                                                    v-model="studentBook.edition"
                                                    placeholder="Editie"
                                                    type="number"
                                                    outlined>
                                                </v-text-field>  
                                                <v-autocomplete
                                                    v-model="studentBook.languageId"
                                                    :items="languages"
                                                    :item-text="'name'"
                                                    :item-value="'id'"
                                                    :rules="[rules.required]"
                                                    label="Taal"
                                                    outlined>
                                                </v-autocomplete>   
                                                <v-menu
                                                    ref="menu"
                                                    v-model="menu"
                                                    :close-on-content-click="false"
                                                    :return-value.sync="studentBook.purchaseDate"
                                                    transition="scale-transition"
                                                    offset-y
                                                    min-width="auto">
                                                    <template v-slot:activator="{ on }">
                                                        <v-text-field
                                                            v-model="studentBook.purchaseDate"
                                                            label="Aangeschaft"
                                                            prepend-icon="mdi-calendar"
                                                            readonly
                                                            outlined
                                                            v-on="on"
                                                        ></v-text-field>
                                                    </template>
                                                    <v-date-picker v-model="studentBook.purchaseDate" no-title scrollable>
                                                        <v-spacer></v-spacer>
                                                        <v-btn text color="primary" @click="menu = false">
                                                            Cancel
                                                        </v-btn>
                                                        <v-btn text color="primary" @click="$refs.menu.save(studentBook.purchaseDate)">
                                                            OK
                                                        </v-btn>
                                                    </v-date-picker>
                                                </v-menu>     
                                                <v-select :items="state"
                                                    v-model="studentBook.state"
                                                    :item-text="'description'"
                                                    :item-value="'id'"
                                                label="Staat" outlined>
                                                </v-select>   
                                                <v-textarea outlined
                                                    v-model="studentBook.description"
                                                    name="input-7-4"
                                                    label="Omschrijving">
                                                </v-textarea>   
                                                <v-card-text>  
                                                    <v-row>
                                                        <v-col cols="12" md="8">
                                                            <v-btn color="primary" align="left"
                                                                :disabled="!formValid"
                                                                outlined @click="updateStudentBook">
                                                                UPDATE MIJN BOEK
                                                            </v-btn>
                                                        </v-col>
                                                        <v-col cols="12" md="2">
                                                            <v-btn color="primary" outlined @click="openMyBooks">
                                                                BACK
                                                            </v-btn>
                                                        </v-col>
                                                    </v-row>     
                                                </v-card-text>     
                                            </v-col>
                                        </v-row>
                                    </v-responsive>
                                </div>
                            </v-responsive>
                        </v-card>
                    </v-form>
                  </template>
              </v-col>
        </v-row>
    </v-container>
</template>

<script>
import UserMenuCard from '../components/UserMenuCard.vue';
import StudentBookService from '../services/BookService';
import LanguageService from "../services/LanguageService"
import VueUploadMultipleImage from 'vue-upload-multiple-image'

  export default {
    data () {
      return {
        studentBookId: this.$route.params.id,
        studentBook: {},
        languages: [],
        images: [],
        imagePreview: null,
        menu: false,
        state: [
            { id: 3, description: 'Nieuw' },
            { id: 0, description: 'Zo goed als nieuw' },
            { id: 1, description: 'Goed' },
            { id: 2, description: 'Redelijk' },
        ],
        university: ['Foo', 'Bar', 'Fizz', 'Buzz'],
        currentPrices: [
            { store: 'Bol.com', price: '€ 50'},
            { store: 'Studystore', price: '€ 60'},
            { store: 'Book Match', price: '€ 45'},
        ],
         rules: {
          required: value => !!value || 'Required.',
        },
      }
    },
    async created() {
        this.studentBook = await this.getStudentBookById();
        let imagesToComponent = [];
        if (this.studentBook != null && this.studentBook.listImageComponentUIDto != null) {
            this.studentBook?.listImageComponentUIDto?.forEach(element => {
                imagesToComponent.push({
                    path: element.path,
                    default: element.default,
                    highlight: element.highlight,
                    name: element.name
                });
            });
            
            this.studentBook.imageChanged = false;
            this.studentBook.listOriginalImages = imagesToComponent;
            this.studentBook.imagesToDisplayBase64 = imagesToComponent; // object variable;
            this.images = imagesToComponent; // component variable;
        }

        await this.loadLanguages();
    },
     computed: {
        formValid() {
            return this.studentBook.isbn &&
                this.studentBook.title &&
                this.studentBook.languageId;
        },
        isbnInformed() {
            return this.studentBook.isbn;
        },
    },
    methods: {
        uploadImageSuccess(formData, index, fileList) {
            this.studentBook.imageChanged = true;

            this.studentBook.listUpdatedImages = [];
            fileList.forEach(element => {
                this.studentBook.listUpdatedImages.push(element);
            });
        },
        beforeRemove (index, done, fileList) {
            var r = confirm("remove image")
            if (r == true) {
                this.studentBook.listUpdatedImages = [];
                done()
                this.studentBook.listUpdatedImages = fileList;
                this.studentBook.imageChanged = true;
            } else {
            }
         },
        editImage (formData, index, fileList) {
            this.studentBook.listUpdatedImages = [];
            this.studentBook.imageChanged = true;
            
            fileList.forEach(element => {
                this.studentBook.listUpdatedImages.push(element);
            });
        },
        markAsPrimary(index, fileList) {
            this.images = fileList;
            this.studentBook.imageChanged = true;
            this.studentBook.listUpdatedImages = fileList;
        },
        async getStudentBookById() {
            return await StudentBookService.getStudentBookById(this.studentBookId);
        },
        async openMyBooks() {
            this.$router.push({name: 'MyBooks'});
        },
        async loadLanguages() {
            this.languages = await LanguageService.getLanguages();
        },        
        async updateStudentBook() {
            if (this.studentBook.edition == "") this.studentBook.edition = null;
            
            await StudentBookService.updateStudentBook(1, this.studentBook).then(() => {
                this.$toast.success("Boek ingeschreven met succes!");
                this.$router.push({name: 'MyBooks'});
            })
            .catch((error) => {
                this.$toast.error("Er is iets fout gegaan!");
            });
        }
    },
    components: {
         UserMenuCard,
         VueUploadMultipleImage
    },
  }
</script>

<style>
h1, h2 {
  font-weight: normal;
}
 
ul {
  list-style-type: none;
  padding: 0;
}
 
li {
  display: inline-block;
  margin: 0 10px;
}
 
a {
  color: #42b983;
}
</style> 