<template>
   <v-form>
        <v-container>
            <v-row justify="center" v-if="errorMessage || loading"> 
                <v-col cols="6" v-if="errorMessage">
                    <v-card-text>
                    {{errorMessage}}
                    </v-card-text>
                </v-col>
                <v-col class="my-8" cols="6">
                    <v-progress-circular
                    v-if="loading"
                    indeterminate
                    color="primary">
                    </v-progress-circular>
                </v-col>
            </v-row>
            <v-row>
                <v-col class="mt-3">
                    <v-row v-for="(book, index) in books" :key="index">
                        <v-card width="100%" class="ma-4">
                            <v-card-title>{{book.title}}</v-card-title>
                            <v-card-subtitle>ISBN: {{book.isbn}}</v-card-subtitle>
                            <v-card-text>Number of views in period: {{book.numberOfViewsInPeriod}}</v-card-text>
                        </v-card>
                    </v-row>
                </v-col>
            </v-row>
            <v-row>
                <v-col cols="3"></v-col>
                <v-col>
                    <v-row>
                        <v-pagination
                            v-if="totalPages >0"
                            style="padding-bottom: 3%; padding-top: 3%"
                            v-model="pageNumber"
                            :length="totalPages"
                            @input="load()"
                            :total-visible="7">
                        </v-pagination>
                    </v-row>
                </v-col>
            </v-row>
        </v-container>
   </v-form>
</template>

<script>
import AdminPublishedBookService from '../../services/AdminPublishedBookService';
import { mapGetters } from 'vuex';

export default {
    data () {
      return {
        loading: false,
        pageSize: 30,
        pageNumber: 0,
        totalPages:0,
        errorMessage: null,
        books: null, 
      }
    },
    async created(){
        await this.load()
    },
    methods:{
        ...mapGetters(['getAccessToken']),
        async load(){
            try{
                this.loading = true;
                let data = await AdminPublishedBookService.getAdminPublishedBooks(this.getAccessToken(), this.pageNumber, this.pageSize);
                this.books = data.books;
                this.loading = false;
            }
            catch { 
                this.errorMessage = 'Er is iets mis gegaan.';
            }
        },
    },
    components:{},
}
</script>