<template>
    <v-card width="550" min-height="600" flat>
        <v-row class="py-4">
            <v-col>
                <v-card-text class="pt-0"> 
                    <v-img max-width="100"
                        :src="book.publishedBook.imageFileName">
                    </v-img>
                </v-card-text>
                <v-card-text class="py-0"> 
                    ISBN: {{ book.publishedBook.isbn }} 
                </v-card-text>
                <v-card-text class="py-0"> 
                    Druk: {{ book.publishedBook.edition }} 
                </v-card-text>
                <v-card-text class="py-0"> 
                    {{ book.publishedBook.title }} 
                </v-card-text>
            </v-col>
        </v-row>
        <v-row class="px-4">
            <v-col cols="4" md="3" class="py-0">
                <v-text-field
                    v-model="book.price" 
                    outlined 
                    dense 
                    placeholder="bijv. 23,99"
                    label="Prijs in €">
                </v-text-field>
            </v-col>
            <v-col cols="8" md="4" class="py-0">
                <v-select
                    return-object
                    :items="conditions"
                    v-model="book.condition"
                    :item-text="'option'"
                    :item-value="'id'"
                    dense
                    label="Staat" outlined>
                </v-select>   
            </v-col>
            <v-col class="mt-n5">
                <v-expansion-panels class="pt-0" v-model="priceHistoryExpansionPanelState">
                    <v-expansion-panel class="pb-4">
                        <v-expansion-panel-header>
                            <v-row>
                                <v-col>
                                    Verkoopgeschiedenis <div></div>
                                </v-col>
                                <v-col class="py-1">
                                    <v-btn v-if="priceHistory.length ==0 " small color="secondary" class="text--black" text outlined @click="getPriceHistory()">laden</v-btn>
                                </v-col>
                            </v-row>
                        </v-expansion-panel-header>
                        <v-expansion-panel-content>
                            <v-row class="justify-center">
                                <v-progress-circular
                                    v-if="loadingPriceHistory"
                                    indeterminate
                                    color="primary">
                                </v-progress-circular>
                            </v-row>

                            <v-card-text class="px-0">
                                <v-btn text outlined small :color="getColor(3)" @click="setBackInTimeMonths(3)">3 maanden</v-btn>
                                <v-btn text outlined small :color="getColor(6)" @click="setBackInTimeMonths(6)">6 maanden</v-btn>
                                <v-btn text outlined small :color="getColor(9)" @click="setBackInTimeMonths(9)">9 maanden</v-btn>
                                <v-btn text outlined small :color="getColor(12)" @click="setBackInTimeMonths(12)">12 maanden</v-btn>
                            </v-card-text>

                            <v-card-text class="px-0" v-if="!loadingPriceHistory && priceHistory != null && priceHistory.length >0">
                                <h4>Gemiddelde prijs: €{{parseFloat(priceHistoryAverage).toFixed(2)}}</h4>
                            </v-card-text>
                            <v-card-text v-if="!loadingPriceHistory && (priceHistory == null || priceHistory.length ==0)">Geen informatie aanwezig</v-card-text>
                            <div v-if="!loadingPriceHistory">
                                <v-row v-for="(transaction, index) in priceHistory" :key="index">
                                    <v-col class="pa-0" cols="5">
                                        <v-card-text  class="py-0">
                                            {{ getFriendlyDate(transaction.date) }}
                                        </v-card-text>
                                    </v-col>
                                    <v-col class="pa-0" cols="7">
                                        <v-card-text  class="py-0">
                                            €{{ transaction.amount }}
                                        </v-card-text>
                                    </v-col>                                
                                </v-row>
                            </div>
                            <v-row>
                                <v-pagination
                                    v-if="totalPagesPriceHistory > 1"
                                    style="padding-bottom: 3%; padding-top: 3%"
                                    v-model="priceHistoryPageNumber"
                                    :length="totalPagesPriceHistory"
                                    @input="getPriceHistory()"
                                    :total-visible="5">
                                </v-pagination>
                            </v-row>
                        </v-expansion-panel-content>
                    </v-expansion-panel>
                </v-expansion-panels>
            </v-col>
            <v-col cols="12" class="py-0">
                <v-textarea
                    outlined
                    v-model="book.ownersNote"
                    name="input-7-4"
                    label="Omschrijving">
                </v-textarea>
            </v-col>
        </v-row>
        <v-row class="my-0 px-4">
            <v-col cols="12" md="6">
                <v-select
                v-model="selectedAccount" 
                :items="accounts"
                chips
                label="Boekshare Accounts"
                :item-text="'userName'"
                :item-value="'id'"
                single-line
                dense
                class="pb-0"
                outlined
                @input="userSelected()">
                </v-select>
            </v-col>
            <v-col cols="12">
                <v-card v-if="userIsSelected">
                    <v-col cols="12" v-if="books != null && books.length > 0">Boeken van de verkoper</v-col>
                    <v-col cols="12" v-if="books.length == 0">Deze verkoper heeft geen boeken</v-col>
                    <v-col cols="12">
                        <h3 v-if="isAlreadySellingTheBook" class="red--text">Deze verkoper verkoopt al deze boek</h3>
                        <v-checkbox v-if="isAlreadySellingTheBook" label="Alsnog doorgaan?" v-model="forceAddingBook"></v-checkbox>
                        <v-btn v-if="forceAddingBook || !isAlreadySellingTheBook" color="primary" :loading="loading" @click="addBook()">Toevoegen</v-btn>
                    </v-col>
                    <v-col cols="12" v-for="(book, index) in books" :key="index">
                        {{ book.publishedBook.title }} - {{ book.publishedBook.isbn }} - €{{ book.price }} - {{ book.insertDate }}
                    </v-col>
                </v-card>
            </v-col>
        </v-row>
        <v-row>
            <v-pagination
                v-if="totalPages > 1"
                style="padding-bottom: 3%; padding-top: 3%"
                v-model="pageNumber"
                :length="totalPages"
                @input="userSelected()"
                :total-visible="4">
            </v-pagination>
        </v-row>
    </v-card>
</template>

<script>
import { mapGetters } from 'vuex';
import StudentService from '../../../services/StudentService';
import AdminBookService from '../../../services/AdminBookService';
import AdminPublishedBookService from '../../../services/AdminPublishedBookService';

export default {
    props: ['book', 'selectedTransaction', 'dateFrom', 'dateTo'],
    data () {
       return {
        accounts: [],
        books: [],
        selectedAccount: null,
        totalPages:0,
        pageNumber: 1,
        pageSize: 5,
        userIsSelected: false,
        forceAddingBook: false,
        isAlreadySellingTheBook: false,
        loading: false,
        priceHistory:[],
        priceHistoryPageNumber: 1,
        priceHistoryPageSize: 5,
        priceHistoryAverage: 0.0,
        loadingPriceHistory: false,
        totalPagesPriceHistory: 0,
        historyBackInTimeMonths: 3,
        priceHistoryExpansionPanelState: null,
        conditions: [
            { id: 0, option: 'Zo goed als nieuw' },
            { id: 1, option: 'Goed' },
            { id: 2, option: 'Redelijk' },
            { id: 3, option: 'Nieuw' },
        ],
       }
    },

    watch: {
        book(newVal, oldVal) {
            this.getPriceHistory();
        }
    },

    async created(){
        await this.getBoekShareAccounts();
        await this.getPriceHistory();
    },

    methods:{
        ...mapGetters(['getAccessToken']),

        getColor(months) {
            return this.historyBackInTimeMonths === months ? "primary" : "grey";
        },

        async setBackInTimeMonths(months){
            this.historyBackInTimeMonths = months;
            this.priceHistoryPageNumber = 1;
            await this.getPriceHistory();
        },

        async setAveragePrice(){
            try{
                let result = await AdminPublishedBookService.getAdminAveragePrice(this.getAccessToken(), 
                this.book.publishedBook.id, 
                this.dateFrom, 
                this.dateTo);

                this.priceHistoryAverage = result.averagePrice;
            }catch{
                this.priceHistoryAverage = 0.0;
            }
        },

        async getPriceHistory(){
            this.loadingPriceHistory = true;
            try{
                this.setDateRange();

                let result = await AdminPublishedBookService.getAdminPriceHistory(this.getAccessToken(), 
                this.book.publishedBook.id, 
                this.dateFrom, 
                this.dateTo,
                this.priceHistoryPageNumber,
                this.priceHistoryPageSize);

                this.priceHistory = result.transactions;
                
                this.totalPagesPriceHistory = Math.ceil(result.totalCount / this.priceHistoryPageSize);
                await this.setAveragePrice();

                if(this.priceHistory.length > 0){
                    this.priceHistoryExpansionPanelState = 0;
                }

            }catch{
                this.loadingPriceHistory = false;
            }

            this.loadingPriceHistory = false;

        },

        formatDate(date) {
            // Haal jaar, maand en dag op en zorg ervoor dat maand en dag altijd twee cijfers hebben
            const year = date.getFullYear();
            const month = String(date.getMonth() + 1).padStart(2, '0'); // Maanden beginnen bij 0
            const day = String(date.getDate()).padStart(2, '0');
            return `${year}-${month}-${day}`;
        },

        setDateRange() {
            const today = new Date();
            const backInTime = new Date();

            // Zet 6 maanden terug
            backInTime.setMonth(today.getMonth() - this.historyBackInTimeMonths);

            // Zet de datums in het juiste formaat
            this.dateTo = this.formatDate(today);
            this.dateFrom = this.formatDate(backInTime);
        },

        getFriendlyDate(val){
            var date = new Date(val);
            return date.getDate()  + "-" + (date.getMonth()+1) + "-" + date.getFullYear();
        },

        getFriendlyTime(val){
            var date = new Date(val);
            return date.getHours() + ":" + String(date.getMinutes()).padStart(2, "0");
        },

        async getBoekShareAccounts(){
            let result = await StudentService.getBoekShareAccounts(this.getAccessToken());
            this.accounts = result.boekShareAcocunts;
        },

        async userSelected(){
            let result = await AdminBookService.getBooksOfStudentAsAdmin(this.getAccessToken(), this.selectedAccount, this.pageNumber, this.pageSize, true);
            this.books = result.books;
            this.userIsSelected = true;
            this.totalPages = Math.ceil(result.totalCount / this.pageSize   );
            await this.isAlreadySellingBook();
        },

        async isAlreadySellingBook(){
            let result = await AdminBookService.isStudentAlreadySellingBook(this.getAccessToken(), this.selectedAccount, this.book.publishedBook.id);
            this.isAlreadySellingTheBook = result.result;
        },

        getCondition(){
            if(this.book.condition.id != null && this.book.condition.id != undefined){
                return this.book.condition.id;
            } else {
                return this.book.condition;
            }
        },

        async addBook(){
            this.loading = true;
            try{
                let book = {};
                book.publishedBookId = this.book.publishedBook.id;
                book.sellOption = 0;
                book.condition = this.getCondition();
                book.deliveryOption = this.book.deliveryOption;
                book.price = this.book.price;
                book.ownersNote = this.book.ownersNote;
                book.readyForSaleDate = (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10);
                await AdminBookService.addBook(this.getAccessToken(), this.selectedAccount, book, this.selectedTransaction.intervene.id);

            } catch{

            }
            
            this.loading = false;
            this.resetForm();
        },

        resetForm(){
            this.books = [];
            this.selectedAccount = null;
            this.userIsSelected = false;
            this.forceAddingBook = false;
            this.isAlreadySellingTheBook = false;
            this.totalPages = 0;
            this.pageNumber = 1;
            this.priceHistory = [];
            this.$emit('closeDialogEvent');
        }
    }
}
</script>