import axios from "../plugins/axios";

const baseUrl = `${process.env.VUE_APP_BOOKSHARE_URL}/publishedbooks/admin/`;

export default {
    async getAdminPublishedBooks(token, pageNumber, pageSize) {
        let result;
        let data = {pageNumber: pageNumber, pageSize:pageSize};

        const config = {
            headers: { Authorization: `Bearer ${token}` }
        };

        await axios.post(`${baseUrl}popular-books`, data, config)
        .then(response =>
        result = response.data);
        return result;
    },

    async getAdminPriceHistory(token, publishedBookId, from, to, pageNumber, pageSize) {
        let result;
        let data = {
            publishedBookId:publishedBookId,
            from:from,
            to:to,
            pageNumber: pageNumber, 
            pageSize:pageSize
        };

        const config = {
            headers: { Authorization: `Bearer ${token}` }
        };

        await axios.post(`${baseUrl}price-history-published-book`, data, config)
            .then(response =>
                result = response.data);
        return result;
    },

    async getAdminAveragePrice(token, publishedBookId, from, to) {
        let result;
        let data = {
            publishedBookId:publishedBookId,
            from:from,
            to:to
        };

        const config = {
            headers: { Authorization: `Bearer ${token}` }
        };

        await axios.post(`${baseUrl}average-price-published-book`, data, config)
            .then(response =>
                result = response.data);
        return result;
    },
}