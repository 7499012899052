<template>
    <v-container class="pt-0">
        <v-card flat class="mt-n3" v-if="errorMessage || loading"> 
            <v-col cols="5" md="8" v-if="errorMessage">
                <v-card-text>
                {{errorMessage}}
                </v-card-text>
            </v-col>
            <v-col class="my-8">
                <v-progress-circular
                v-if="loading"
                indeterminate
                color="primary">
                </v-progress-circular>
            </v-col>
        </v-card>
        <v-row class="mb-4">
            <v-col cols="12">
                <SubsscriptionLimitsimport></SubsscriptionLimitsimport> 
                <v-row v-if="!loading && !errorMessage && ((conversations && conversations.length ==0) || !conversations)">
                    <v-col cols="12" class="pt-0">
                            <v-card flat>
                                <v-card-subtitle>
                                    Je hebt nog geen berichten.
                                </v-card-subtitle>
                            </v-card>
                    </v-col>
                </v-row>
                <v-card flat class="mb-6" v-for="(conversation, index) in conversations" :key="index">
                    <v-row>
                        <v-col cols="12" md="2">
                            <v-card-title>
                                <v-badge
                                    v-if="countUnreadMessages(conversation)>0"
                                    :content="countUnreadMessages(conversation)"
                                    color="primary"
                                    overlap>
                                    <v-img :src="getPhotoOfUser(conversation)" max-width="80"></v-img>
                                </v-badge>
                                <v-img v-if="countUnreadMessages(conversation)===0" :src="getPhotoOfUser(conversation)" max-width="80"></v-img>
                            </v-card-title>
                        </v-col>
                        <v-col>
                            <v-row>
                                <v-col>
                                    <v-card-subtitle>
                                        <v-row class="font-weight-bold">
                                            <v-col cols="8" class="py-0 headline">
                                                {{getSendingToUserMessage(conversation).firstName}} {{getSendingToUserMessage(conversation).lastName}}
                                            </v-col>
                                        </v-row>
                                    </v-card-subtitle>
                                </v-col>
                            </v-row>
                            <v-row class="pt-0">
                                <v-col class="pt-0">
                                    <v-card-text v-if="conversation.book">
                                        <v-row>
                                            <v-col cols="4" md="4" class="py-0 font-weight-medium">
                                                Boek:
                                            </v-col>
                                            <v-col class="py-0">
                                                {{conversation.book.publishedBook.title}}
                                            </v-col>
                                        </v-row>
                                    </v-card-text>
                                    <v-card-text class="py-1">
                                        <v-row>
                                            <v-col cols="4" md="4" class="py-0 font-weight-medium">
                                                ISBN:
                                            </v-col>
                                            <v-col class="py-0">
                                                {{conversation.book.publishedBook.isbn}}
                                            </v-col>
                                        </v-row>
                                    </v-card-text>
                                    <v-card-text class="py-1">
                                        <v-row>
                                            <v-col cols="4" md="4" class="font-weight-medium">
                                                Laatste bericht:
                                            </v-col>
                                            <v-col v-if="conversation.userMessages.length>0">
                                                {{conversation.userMessages[conversation.userMessages.length-1].text}}
                                            </v-col>
                                        </v-row>
                                    </v-card-text>
                                </v-col>
                            </v-row>
                        </v-col>
                        <v-col cols="12" md="3" align-self="center">
                            <v-row>
                                <v-col cols="7" md="3">
                                </v-col>
                                <v-col>
                                    <v-btn small color="primary" @click="showConversationDetails(conversation)">
                                        BEKIJKEN
                                    </v-btn>
                                </v-col>
                            </v-row>
                        </v-col>
                    </v-row>
                </v-card>
            </v-col>
        </v-row>
        <v-row>
            <v-col>
                <v-pagination
                    v-if="hasValidSubscription() && totalPages > 0"
                    style="padding-bottom: 3%; padding-top: 3%"
                    v-model="page"
                    :length="totalPages"
                    @input="next"
                    :total-visible="5">
                </v-pagination>
            </v-col>
        </v-row>
        
    </v-container>
</template>


<script>
import { mapActions, mapGetters } from 'vuex'
import ConversationService from '../services/ConversationService'
import SubsscriptionLimitsimport from '../components/SubscriptionLimitsState.vue';
import NoAccount from "../assets/img/no-user.png";

export default {
    props: { },
    data () {
        return {
            loading:false,
            errorMessage: null,
            messages: null,
            conversations:null,
            validSubscription: false,
            totalPages: 5,
            pageSize: 10,
            page: 1,
        }
    },
    async mounted(){
         this.loadMyMessages();
    },
    async created() {
       
    },
    components:{
        SubsscriptionLimitsimport,
    },
    methods: {
        ...mapGetters(['getAccessToken']),
        ...mapActions(['saveNumberOfUnreadMessages']),
        ...mapGetters(['hasValidSubscription']),
        ...mapGetters(['getStudent']),
        showConversationDetails(conversation){
            this.$router.push({name:'SendMessage', params:{book:conversation.book, conversation:conversation}});
        },

        async loadMyMessages(){
            try{
                this.loading = true;
                this.errorMessage = null;
                let result = await ConversationService.getMyConversations(this.getAccessToken(), this.page, this.pageSize);
                this.conversations = result.conversations;
                this.saveNumberOfUnreadMessages(result.numberOfUnreadMessages);
                this.totalPages = Math.ceil(result.totalCount / this.pageSize);
                
            } catch{
                this.errorMessage = 'Er is een fout opgetreden.'
            }
            this.loading = false;
        },

        async next(p) {
            try{
                    this.loading = true;
                    this.errorMessage = null;
                    let result = await ConversationService.getMyConversations(this.getAccessToken(), this.page, this.pageSize);
                    this.conversations = result.conversations;
                    this.saveNumberOfUnreadMessages(result.numberOfUnreadMessages);
                    this.totalPages = Math.ceil(result.totalCount / this.pageSize);
            }
            catch{
                this.errorMessage = 'Er is een fout opgetreden.'
            }
            this.loading = false;
        },

        countUnreadMessages(conversation){
            let count = 0;
            conversation.userMessages.forEach(userMessage => {
                if(userMessage.readTime === null && this.getStudent().id === userMessage.to){
                    count++;
                }
            });

            return count;
        },

        getSendingToUserMessage(conversation){
            if(conversation) {
                if(conversation.to.id === this.getStudent().id){
                    this.setPhotoOfUser(conversation.from);
                    return conversation.from;
                } else{
                    this.setPhotoOfUser(conversation.to);
                    return conversation.to;
                } 
            } else{
                this.setPhotoOfUser(this.getStudent());
                return this.getStudent();
            }
        },

        getPhotoOfUser(conversation){
            if(conversation) {
                if(conversation.to.id === this.getStudent().id){
                    return this.setPhotoOfUser(conversation.from);
                } else{
                    return this.setPhotoOfUser(conversation.to);
                } 
            } else{
                return this.setPhotoOfUser(this.getStudent());
            }
        },

        setPhotoOfUser(student){
            if(student.photo){ 
                return student.photo;
               
            } else{
                return NoAccount;
            }
        }
    }
}
</script>