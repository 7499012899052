<template>
    <v-container>
         <v-dialog
            max-width="600"
            transition="dialog-top-transition"
            v-model="showReviewsDialog">
            <template class="ma-0">
                <v-card>
                    <OverviewStudentReviews
                        v-if="selectedOwnerIdForRating != null" 
                        @closeDialog="closeDialog" 
                        :sellerId="selectedOwnerIdForRating">
                    </OverviewStudentReviews>
                </v-card>
            </template>
        </v-dialog>
        <v-row>
            <v-breadcrumbs divider="-">
                <v-breadcrumbs-item href="/">&lt; terug naar Home</v-breadcrumbs-item>
            </v-breadcrumbs>
        </v-row>
        <v-row justify="center">
            <v-dialog v-model="dialogPicture" max-width="650">
                <v-card>
                    <v-row justify="end" class="ma-2">
                    <v-card-actions>
                        <v-btn text @click="dialogPicture = false">
                            <v-icon>
                                mdi-close
                            </v-icon>
                        </v-btn>
                    </v-card-actions>
                    </v-row>
                    <v-row>
                    <v-carousel v-if="selectedBook">
                        <v-carousel-item
                        v-for="(image,i) in selectedBook.images"
                        :key="i"
                        :src="image.fileNameFullPath"
                        reverse-transition="fade-transition"
                        transition="fade-transition"
                        ></v-carousel-item>
                    </v-carousel>
                    </v-row>
                </v-card>
            </v-dialog>
        </v-row>
        <v-row>
            <v-col lg="4" md="1" cols="12">
                <v-card class="px-8">
                    <v-row justify="center" class="mb-6">
                        <v-img class="mt-6" 
                        v-if="publishedBook && publishedBook.imageFileName"
                        :src="publishedBook.imageFileName"
                        max-width="150">
                        </v-img>
                    </v-row>
                    <v-row v-if="publishedBook">
                        <v-col>
                            <v-row class="ma-0">
                                <v-col class="ma-0 pa-0" cols="4">
                                    <label class="font-weight-bold">Naam:</label>
                                </v-col>
                                <v-col class="ma-0 pa-0" id="labelPublishedBookTitle">
                                     {{publishedBook.title}}
                                </v-col>      
                            </v-row>
                            <v-row class="ma-0">
                                <v-col class="ma-0 pa-0" cols="4" md="4">
                                    <label class="font-weight-bold">Auteur:</label>
                                </v-col>
                                <v-col class="ma-0 pa-0" id="labelPublishedBookAuthor">
                                    {{publishedBook.author}}
                                </v-col>
                            </v-row>
                            <v-row class="ma-0">
                                <v-col class="ma-0 pa-0" cols="4">
                                    <label class="font-weight-bold">ISBN:</label>
                                </v-col>
                                <v-col class="ma-0 pa-0"  id="labelPublishedBookIsbn">
                                    {{publishedBook.isbn}}
                                </v-col>
                                
                            </v-row>
                            <v-row class="ma-0">
                                <v-col class="ma-0 pa-0" cols="4">
                                    <label class="font-weight-bold">Druk:</label>
                                </v-col>
                                <v-col class="ma-0 pa-0" id="labelPublishedBookEdition">
                                    {{publishedBook.edition}}
                                </v-col>
                            </v-row>
                            <v-row class="ma-0" v-if="isAdmin()">
                                <v-col class="ma-0 pa-0" cols="3">
                                    
                                 </v-col>
                                 <v-col class="ma-0 pa-0">
                                     <v-row justify="end" @click="editPublishedBook()">
                                     <v-btn text color="primary">
                                        <v-icon aria-hidden="false">
                                        mdi-pencil
                                        </v-icon>
                                     </v-btn>
                                     </v-row>
                                 </v-col>
                            </v-row>
                        </v-col>
                    </v-row>
                    <v-col>
                    </v-col>
                </v-card>
            </v-col>
            <v-col v-if="books && books.length == 0 && isUserLoggedIn() && !errorMessage" class="pt-0">
                <v-card class="pa-5">
                    <v-card-title>Helaas!</v-card-title>
                    <v-card-text>
                        <p>Dit boek wordt op Boekshare niet aangeboden.</p>
                        <p>Het boek staat nu in je <router-link to="/mypublishedbookalerts">mijn verlanglijstje.</router-link></p>
                    </v-card-text>
                </v-card>
            </v-col>
            <v-col v-if="books && books.length == 0 && !isUserLoggedIn() &&  !errorMessage" class="pt-0">
                <v-card class="pa-5">
                    <v-card-title>Helaas!</v-card-title>
                    <v-card-text>
                        <p>Dit boek wordt op Boekshare niet tweedehands aangeboden. Als je een account aanmaakt, 
                            kunnen we dit boek voor je op je verlanglijstje zetten. 
                            Je krijgt dan een e-mail als het tweedehands wordt aangeboden. Goed idee?</p>
                    </v-card-text>
                    <v-card-actions class="ml-2">
                        <v-row>
                            <v-col cols="12" md="4">
                                <router-link to="/registerstudent" tag="span">
                                    <v-btn color="primary" outlined>
                                        Maak een account
                                    </v-btn>
                                </router-link>
                            </v-col>
                            <v-col>
                                <router-link to="/login">
                                    <v-btn color="primary" tag="span">
                                            Ik heb al een account
                                    </v-btn>
                                </router-link>
                            </v-col>
                        </v-row>
                    </v-card-actions>
                </v-card>
            </v-col>
            <v-col v-if="errorMessage">
                <v-row justify="center">
                    <label class="red--text">{{errorMessage}}</label>
                </v-row>
            </v-col>
            <v-col md="8" v-if="books && books.length > 0 && !errorMessage">
                <v-row>
                    <v-col v-if="loading">
                        <v-row justify="center">
                            <v-progress-circular
                                indeterminate
                                color="primary">
                            </v-progress-circular>
                        </v-row>
                    </v-col>
                </v-row>
                <v-row justify="end" class="pb-0">
                    <v-col cols="12" md="4" class="pb-0"> 
                        <v-select
                            :items="sortingOptions"
                            v-model="selectedFilter"
                            :item-text="'value'"
                            :item-value="'id'"
                            label="Sorteren"
                            @input="getBooksOfPublishedBook()"
                            dense
                            outlined>
                        </v-select>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col>
                        <v-card class="mb-8" v-for="(book, index) in books" :key="index">
                            <v-row>
                                <v-col class="ml-4" cols="2" md="2">
                                    <v-avatar size="40">
                                        <!-- <v-icon v-if="!book.owner.photo">mdi-account</v-icon> -->
                                        <v-img v-if="!book.owner.photo" :src="getDefaultImageAvatar()"></v-img>
                                        <v-img v-if="book.owner.photo" :src="book.owner.photo"></v-img>
                                    </v-avatar>
                                </v-col>
                                <v-col>
                                    <v-row>
                                        <v-col>
                                            <router-link class="btn btn-link" :to="{name: 'BooksOfStudentDetails', params: { student: book.owner}}">
                                                {{book.owner.firstName}} {{book.owner.lastName}}
                                            </router-link>
                                        </v-col>
                                        <v-col cols="12" md="8">
                                            <v-row v-if="book.owner.reviews.length > 0">
                                                <v-col cols="6" md="5" class="pt-2">
                                                    <v-btn text class="ma-0 pa-0" @click="showReviewRating(book.owner.id)">
                                                        <v-rating
                                                            readonly
                                                            v-model="book.owner.reviewScore"
                                                            color="yellow darken-3"
                                                            background-color="grey darken-1"
                                                            empty-icon="$ratingFull"
                                                            half-increments
                                                            dense
                                                            hover>
                                                        </v-rating>
                                                    </v-btn>
                                                </v-col>
                                                <v-col class="mt-1" cols="4" v-if="book.owner.reviews.length == 1">
                                                    {{book.owner.reviews.length}} review
                                                </v-col>
                                                <v-col class="mt-1" cols="4" v-if="book.owner.reviews.length > 1">
                                                    {{book.owner.reviews.length}} reviews
                                                </v-col>
                                            </v-row>
                                        </v-col>
                                    </v-row>
                                    <v-row>
                                        <v-col>
                                            <label v-if="book.owner.study">{{book.owner.study.name}}, </label>
                                            <label v-if="book.owner.university">{{book.owner.university.name}}</label>
                                        </v-col>
                                    </v-row>
                                </v-col>
                            </v-row>
                            <v-divider class="my-3"></v-divider>
                            <v-row>
                                <v-col cols="12" md="5">
                                    <v-col class="py-0 my-0">
                                        <v-row>
                                            <v-col class="pl-6 pb-0" cols="12">
                                                <label class="font-weight-bold">Conditie: </label>
                                                <label :id="'labelValueCondition'+book.id">{{getNameOfCondition(book.condition)}}</label>
                                            </v-col>
                                            <v-col class="pl-6 py-0">
                                                <label class="font-weight-bold">Prijs: </label>
                                                <label :id="'labelValuePrice'+book.id" class="pl-2">€{{parseFloat(book.price).toFixed(2)}}</label>
                                            </v-col>
                                        </v-row>
                                        <v-row>
                                            <v-col class="pl-6 py-0" cols="12">
                                                <label class="font-weight-bold">Kopersbescherming: </label>
                                                <label :id="'labelValueBuyersProtection'+book.id">€0.99</label>
                                            </v-col>
                                        </v-row>
                                    </v-col>
                                </v-col>
                                <v-col cols="12" md="4">
                                    <v-col class="py-0 my-0">
                                        <v-row class="pl-6 pl-md-0">
                                            <label class="font-weight-bold">Aangeboden sinds: </label>
                                            <label class="pl-2">{{numberOfDays(book.offeredSince)}}</label>
                                        </v-row>
                                        <v-row class="pl-6 pl-md-0">
                                            <label class="font-weight-bold mr-1">Levering:</label>
                                            <label :id="'labelValueDeliveryOption'+book.id">{{getDeliveryOption(book)}}</label>
                                        </v-row>
                                        <v-row class="pl-6 pl-md-0">
                                            <label class="font-weight-bold mr-1">Verzendkosten:</label>
                                            <label :id="'labelValueShippingCosts'+book.id">€{{calculateShippingCosts(publishedBook)}}</label>
                                        </v-row>
                                    </v-col>
                                </v-col>                 
                                <v-col cols="12" lg="3" class="pb-0">
                                    <v-row justify="end" class="px-md-4">
                                        <v-col cols="12" md="12">
                                            <v-btn
                                                :id="'addToCartButton'+book.id"
                                                block 
                                                color="primary" 
                                                v-if="((book.state.state === 1) || (book.state.state === 0)) && 
                                                getStudent().id !== book.owner.id &&
                                                isReservationExpired(book)" 
                                                @click="addToShoppingCart(book)" :loading="loadingBooksOfStudent && book.id === selectedIndex">
                                                <!-- IN WINKELWAGEN -->
                                                <v-icon>mdi-cart</v-icon>
                                            </v-btn>
                                            <v-btn 
                                                :id="'sendMessageButton'+book.id"
                                                block 
                                                color="primary" 
                                                outlined
                                                class="my-2 ml-0"
                                                @click="sendMessage(book, book.owner)">
                                                <!-- BERICHT STUREN -->
                                                <v-icon>mdi-message-text-outline</v-icon>
                                            </v-btn>
                                            <v-btn
                                                :id="'reservedMessageButton'+book.id" 
                                                block 
                                                color="primary" 
                                                class="my-2 px-1"
                                                disabled="true" 
                                                v-if="((book.state.state === 0) && 
                                                getStudent().id !== book.owner.id &&
                                                !isReservationExpired(book))" 
                                                @click="addToShoppingCart(book)">
                                                GERESERVEERD
                                            </v-btn>
                                            <v-btn 
                                                block 
                                                color="secondary"
                                                class="black--text my-2 ml-0"
                                                @click="goToMyBooks()"                                     
                                                v-if="getStudent().id === book.owner.id">
                                                MIJN BOEKEN
                                                <!-- <v-icon>mdi-book-open-variant-outline</v-icon> -->
                                            </v-btn>
                                        </v-col>
                                    </v-row>
                                </v-col>
                                <v-col cols="10">
                                    <v-row class="ml-3" justify="start">
                                        <v-col cols="3" v-for="(image, index) in book.images" :key="index">
                                            <v-img max-width="150" :src="image.fileNameFullPath" @click="selectedImage = image; dialogPicture = true; selectedBook = book;">
                                            </v-img>
                                        </v-col>
                                    </v-row>
                                </v-col>
                            </v-row>
                            <v-row v-if="book.owner.averageShipmentDays != 0.0">
                                <v-col class="ml-6">
                                    <label class="font-weight-bold mr-1">Verstuurt meestal binnen:</label>
                                    <label v-if="book.owner.averageShipmentDays != 0.0 
                                                && book.owner.averageShipmentDays < 2.0" 
                                                :id="'labelValueShippingTime'+book.id">1 dag
                                    </label>
                                        <label v-if="book.owner.averageShipmentDays >= 2.0 
                                            && book.owner.averageShipmentDays <= 5.0" 
                                            :id="'labelValueShippingTime'+book.id">
                                            {{ Math.floor(book.owner.averageShipmentDays) }} dagen
                                        </label>
                                </v-col>
                            </v-row>
                            <v-row v-if="book.ownersNote != null && book.ownersNote != ''" class="my-0">
                                <v-col class="ml-6">
                                    <label 
                                        v-if="book.ownersNote && book.ownersNote.length > 0" 
                                        class="font-italic">"{{book.ownersNote}}"
                                    </label>
                                </v-col>
                            </v-row>
                        </v-card>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col>
                        <v-row>
                            <v-pagination
                                v-if="totalPages > 1"
                                style="padding-bottom: 3%; padding-top: 3%"
                                v-model="pageNumber"
                                :length="totalPages"
                                @input="getBooksOfPublishedBook()"
                                :total-visible="7">
                            </v-pagination>
                        </v-row>
                    </v-col>
                </v-row>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex'
import moment from 'moment'
import BookService from '../services/BookService'
import PublishedBookService from '../services/PublishedBookService'
import StudentService from '../services/StudentService'
import OverviewStudentReviews from '../components/OverviewStudentReviews.vue'
import NoAccount from "../assets/img/no-user.png";

export default {
    data() {
        return {
        books: null,
        booksOfStudent:[],
        dialog: false,
        pb: {},
        studentImages: [],
        loading: false,
        loadingBooksOfStudent: false,
        errorMessage: null,
        selectedIndex: 0,
        selectedImage: null,
        selectedBook: null,
        dialogPicture: false,
        publishedBook: null,
        showReviewsDialog:false,
        selectedOwnerIdForRating:null,
        selectedFilter: 0,
        totalPages: 0,
        pageSize: 10,
        pageNumber: 1,
        sortingOptions:[
            {id:0, value:'Prijs - laag naar hoog'},
            {id:1, value:'Upload datum - nieuw naar oud'}]
        }
    },
    async created() {
        if(this.$route.query.publishedbook){
            await this.getPublishedBook(this.$route.query.publishedbook);
        } else {
            this.publishedBook = this.getStateOfPublishedBookOverview().publishedBook;
            this.pb = this.getNavigationSearch();
        }

        await this.getBooksOfPublishedBook();
    },
    watch: {
        async navigationSearch(newValue, oldValue) {
            if (newValue) {
                this.pb = newValue;
                await this.getBooksOfPublishedBook();
            }
        },
    },
    computed:{
        ...mapState(['navigationSearch']),
    },

    components:{
        OverviewStudentReviews
    },

    methods:{
        ...mapGetters(['getStateOfPublishedBookOverview']),
        ...mapGetters(['getAccessToken']),
        ...mapActions(['saveToCart']),
        ...mapGetters(['getNavigationSearch']),
        ...mapGetters(['isUserLoggedIn']),
        ...mapGetters(['hasValidAddress']),
        ...mapGetters(['getStudent']),
        ...mapGetters(['hasValidSubscription']),
        ...mapGetters(['isAdmin']),

        getDefaultImageAvatar(){
            return NoAccount;
        },
        
        showReviewRating(ownerId){
            this.selectedOwnerIdForRating = ownerId;
            this.showReviewsDialog = true;
        },

        goToMyBooks(){
            this.$router.push({name:'MyBooks'});
        },

        async getBooksOfPublishedBook(){
            try{
                this.loading = true;
                let result = await BookService.getBooksOfPublishedBook(this.getAccessToken(), this.pb.id, this.pageNumber, this.pageSize, this.selectedFilter);
                this.books = result.books;
                this.totalPages = Math.ceil(result.totalCount /this.pageSize);
                window.scrollTo(0, 0);
                if(this.books && this.books.length == 0 && this.isUserLoggedIn()){
                    await StudentService.createAlert(this.getAccessToken(),this.pb.id, true);
                }
                this.loading = false;
            }
            catch{
                this.errorMessage = 'Er is helaas een fout opgetreden.';
            }
            this.loading = false;
        },
        async getPublishedBook(publishedBookId){
            try{
                this.loading = true;
                let publishedBook = await PublishedBookService.getPublishedBook(publishedBookId);
                this.pb = publishedBook;
                this.publishedBook = publishedBook;
                this.loading = false;
            }
            catch{
                this.errorMessage = 'Er is helaas een fout opgetreden.';
            }
            this.loading = false;
        },
        signIn(){
            this.$router.push({name:'LoginUser'});
        },

        editPublishedBook(){
            this.$router.push({ name: 'PublishedBooksAdminAddOrUpdate', params:{ publishedBookToEdit: this.publishedBook }});
        },
        sendMessage(book, to){
            this.$router.push({name:'SendMessage', params:{book:book, to:to}});
        },
        numberOfDays(providedSince){
            const date1Str = new Date(String(providedSince));
            const date2Str = new Date();
            const diffTime = Math.round((date2Str-date1Str)/(1000*60*60*24));
            if(diffTime > 31 && diffTime < 366){
                return (Math.round(diffTime/30)) + " maanden";
            } else if(diffTime > 366){
                return (Math.round(diffTime/365)) + " jaar";
            } 
            else{
                return diffTime + " dagen";
            }
        },
        async addToShoppingCart(book){
            this.selectedIndex = book.id;

            this.$gtag.event("click-add-book-to-shoppingcart", {
            'event_category': "shoppingcart",
            'event_label': "Use clicked to add the book to the shoppingcart.",
            'value': book.publishedBook.isbn
            });

            this.saveToCart(book);
            await this.claimBook(book);
            if(book.owner.numberOfBooks > 1){
                try{
                    this.loadingBooksOfStudent = true;
                    this.errorMessage = null;
                    let result = await BookService.getBooksOfStudent(this.getAccessToken(), book.owner.id, 1, 10, true);
                    this.booksOfStudent = result;
                }
                catch{
                    this.errorMessage = 'Er is een fout opgetreden.';
                }
                this.loadingBooksOfStudent = false;
                
            }else{
                await this.goToShoppingCart();
            }
            this.dialog = true;
        },

        getNameOfCondition(quality){
            if(quality == 0) return "Zo goed als nieuw";
            else if(quality == 1) return "Goed";
            else if(quality == 2) return "Redelijk";
            else if(quality == 3) return "Nieuw";
            else "Onbekend";
        },

        getDeliveryOption(book){
            if(book.deliveryOption === 0){
                return "Verzenden";
            } else if(book.deliveryOption === 1){
                return "Ophalen";
            } else{
                return "Onbekend";
            }
        },

        calculateShippingCosts(pb){
            let totalWeight = Number(pb.physicalProperties.weight); 
            let totalHeight = Number(pb.physicalProperties.height); 

            if(totalWeight<=2000 && totalHeight < 32) {
                return '4.25';
            }
            else if(totalWeight>2000 && 
                    totalWeight<=10000 ||
                    (totalHeight >= 32 && totalHeight <= 500)){
                return '6.95';
            }
            else if(totalWeight>10000 && 
                    totalWeight<=20000 &&
                    totalHeight <= 500){
                return '13.20';
            }
            return '6.95';
        },

        isReservationExpired(book){
            let releaseTime = moment(moment(String(book.state.claimIsValidUntil)).format('YYYY-MM-DD HH:mm:ss')); //2022-01-26T14:26:34.084181
            let now = moment();
            let difference = moment.duration(now.diff(releaseTime));
            return (difference.minutes() >= 0 );
        },

        async claimBook(book){
            try{
                this.loadingBooksOfStudent = true;
                this.errorMessage = null;
                let result = await BookService.claimBook(this.getAccessToken(), book.id);
            }
            catch{
                // this call is not really 
            }
            this.loadingBooksOfStudent = false;
        },

        async goToShoppingCart(){
            if (this.$route.path != '/ShoppingCart') {
                this.$router.push({ name: 'ShoppingCart'});
            }
        },

        closeDialog(){
            this.showReviewsDialog = false;
            this.selectedOwnerIdForRating = null;
        },
    }
}
</script>