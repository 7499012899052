<template>
    <v-form>
         <v-container>
             <v-row>
                 <v-col cols="3" class="hidden-md-and-down">
                     <UserMenuCard></UserMenuCard>   
                 </v-col>
                 <v-col>
                     <v-row justify="center" v-if="loading">
                         <v-progress-circular
                         indeterminate
                         color="primary">
                         </v-progress-circular>
                     </v-row>
                     <v-row>
                        <v-col>
                            <v-dialog v-model="dialogBooksOfBoekShareAccount">
                                <BooksOfBoekShareAccount ref="accountComponent" 
                                    :book="selectedBookToSellAgain" 
                                    :dateFrom="dateFrom"
                                    :dateTo="dateTo"
                                    :selectedTransaction="selectedTransaction"
                                    @closeDialogEvent="closeDialog()">
                                </BooksOfBoekShareAccount>
                            </v-dialog>
                        </v-col>
                    </v-row>
                    <v-row>
                        <!---- OPEN ---->
                        <v-dialog v-model="showOpenDialog" width="850">
                            <v-card>
                                <v-row justify="end" class="ma-2">
                                    <v-card-actions>
                                        <v-btn text @click="closeDialog()">
                                            <v-icon>
                                                mdi-close
                                            </v-icon>
                                        </v-btn>
                                    </v-card-actions>
                                </v-row>
                                <v-row>
                                    <v-col>
                                        <v-card flat class="pa-4 mb-4">
                                            <v-row>
                                                <v-col v-if="selectedTransaction != null">
                                                    <v-card-text>
                                                        <div class="font-weight-bold">{{ selectedTransaction.seller.firstName }}, {{ selectedTransaction.seller.lastName }}</div>
                                                        <div>{{ selectedTransaction.seller.email }}</div>
                                                    </v-card-text> 
                                                </v-col>
                                                <v-col v-if="selectedTransaction != null">
                                                    <v-card-text>
                                                        <div class="font-weight-bold">{{ selectedTransaction.buyer.firstName }}, {{ selectedTransaction.buyer.lastName }}</div>
                                                        <div>{{ selectedTransaction.buyer.email }}</div>
                                                        <div>{{ selectedTransaction.buyer.postalAddress.street }} {{ selectedTransaction.buyer.postalAddress.houseNumber }} {{ selectedTransaction.buyer.postalAddress.additionHouseNumber }}</div>
                                                        <div>{{ selectedTransaction.buyer.postalAddress.postalCode }} {{ selectedTransaction.buyer.postalAddress.city }}</div>
                                                    </v-card-text>
                                                </v-col>
                                            </v-row>
                                            <v-row class="my-0" v-if="selectedTransaction != null">
                                                <v-col>
                                                    <v-card-text class="py-0"> 
                                                        ISBN: {{ selectedTransaction.book.publishedBook.isbn }} 
                                                    </v-card-text>
                                                    <v-card-text class="py-0"> 
                                                        {{ selectedTransaction.book.publishedBook.title }} 
                                                    </v-card-text>
                                                    <v-card-text class="py-0">
                                                        Datum: {{ getFriendlyDate(selectedTransaction.date) }} {{ getFriendlyTime(selectedTransaction.date) }}
                                                    </v-card-text>
                                                    <v-card-text class="py-0">
                                                        Verkocht voor: €{{selectedTransaction.amount}}
                                                    </v-card-text>
                                                    <v-card-text class="py-0" v-if="boughtPrice > 0.0">
                                                        Winst:{{ parseFloat(profit).toFixed(2) }}
                                                    </v-card-text>
                                                </v-col>
                                                <v-col>
                                                    <v-card-text class="py-0">
                                                        <v-text-field label="Gekocht" v-model="boughtPrice" @keyup="onBoughtPriceModified()"></v-text-field>
                                                    </v-card-text>

                                                    <v-card-text class="py-0">
                                                            <v-select class="py-0"
                                                                :items="platforms"
                                                                v-model="platform"
                                                                :item-text="'value'"
                                                                :item-value="'id'"
                                                                label="Welke platform?"
                                                                dense>
                                                            </v-select>
                                                    </v-card-text>
                                                    <v-card-text class="py-0">
                                                        <v-text-field label="Betaal link" v-model="url"></v-text-field>
                                                    </v-card-text>
                                                    <v-card-text class="py-0">
                                                        <v-text-field label="Track and trace code of URL" v-model="trackAndTrace"></v-text-field>
                                                    </v-card-text>
                                                </v-col>
                                            </v-row>
                                            <v-row>
                                                <v-col>
                                                    <v-btn color="secondary" class="black--text mr-4" @click="showInterveneDialog = true">
                                                        Annuleren
                                                    </v-btn>
                                                    <v-btn color="primary" class="mr-4" @click="saveIntervene()">
                                                        Opslaan
                                                    </v-btn>
                                                </v-col>
                                            </v-row>
                                        </v-card>
                                    </v-col>
                                </v-row>
                            </v-card>
                        </v-dialog>
                        <!---- INTERVENE ---->
                        <v-dialog v-model="showInterveneDialog" max-width="800">
                            <v-card>
                                <v-row justify="end" class="ma-2">
                                    <v-card-actions>
                                        <v-btn text @click="closeDialog()">
                                            <v-icon>
                                                mdi-close
                                            </v-icon>
                                        </v-btn>
                                    </v-card-actions>
                                </v-row>
                                <v-row>
                                    <v-col>
                                        <v-card flat class="pa-4 mb-4">
                                            <v-row>
                                                <v-col v-if="selectedTransaction != null">
                                                    <v-card-text>
                                                        <div class="font-weight-bold">{{ selectedTransaction.seller.firstName }}, {{ selectedTransaction.seller.lastName }}</div>
                                                        <div>{{ selectedTransaction.seller.email }}</div>
                                                    </v-card-text> 
                                                </v-col>
                                                <v-col v-if="selectedTransaction != null">
                                                    <v-card-text>
                                                        <div class="font-weight-bold">{{ selectedTransaction.buyer.firstName }}, {{ selectedTransaction.buyer.lastName }}</div>
                                                        <div>{{ selectedTransaction.buyer.email }}</div>
                                                        <div>{{ selectedTransaction.buyer.postalAddress.street }} {{ selectedTransaction.buyer.postalAddress.houseNumber }} {{ selectedTransaction.buyer.postalAddress.additionHouseNumber }}</div>
                                                        <div>{{ selectedTransaction.buyer.postalAddress.postalCode }} {{ selectedTransaction.buyer.postalAddress.city }}</div>
                                                    </v-card-text>
                                                </v-col>
                                            </v-row>
                                            <v-row class="my-0" v-if="selectedTransaction != null && selectedTransaction.intervene != null">
                                                <v-col>
                                                    <v-card-text class="py-0"> 
                                                        ISBN: {{ selectedTransaction.book.publishedBook.isbn }} 
                                                    </v-card-text>
                                                    <v-card-text class="py-0"> 
                                                        {{ selectedTransaction.book.publishedBook.title }} 
                                                    </v-card-text>
                                                    <v-card-text class="py-0">
                                                        Datum: {{ getFriendlyDate(selectedTransaction.date) }} {{ getFriendlyTime(selectedTransaction.date) }}
                                                    </v-card-text>
                                                    <v-card-text class="pb-0">
                                                        {{ trackAndTrace }}
                                                    </v-card-text>
                                                </v-col>
                                                <v-col>
                                                    <v-card-text class="py-0">
                                                        Gekocht: {{ parseFloat(selectedTransaction.intervene.boughtFor).toFixed(2) }}
                                                    </v-card-text>
                                                    <v-card-text class="py-0">
                                                        Verkocht: {{ parseFloat(soldPrice).toFixed(2) }}
                                                    </v-card-text>
                                                    <v-card-text class="py-0">
                                                        Winst: €{{ (parseFloat(selectedTransaction.intervene.soldFor) - parseFloat(selectedTransaction.intervene.boughtFor)).toFixed(2) }}
                                                    </v-card-text>
                                                </v-col>
                                            </v-row>
                                            <v-row>
                                                <v-col cols="12">
                                                    <vue-editor placeholder="Write Something..." v-model="formattedMessage"></vue-editor>
                                                </v-col>
                                                <v-col cols="12">
                                                    <v-row justify="end" class="mr-2">
                                                        <v-btn color="red" :loading="loading" v-if="selectedTransaction !== null && selectedTransaction.state !== 'rejected'" class="white--text mr-4" @click="dontHaveTheBook(selectedTransaction)">Ik heb het boek niet meer</v-btn>
                                                        <v-btn class="mr-4" @click="showInterveneDialog = false">Annuleren</v-btn>
                                                        <v-btn color="primary" @click="onSendMessage()"><v-icon>mdi-send</v-icon></v-btn>
                                                    </v-row>
                                                </v-col>
                                            </v-row>
                                            
                                        </v-card>
                                    </v-col>
                                </v-row>
                            </v-card>
                        </v-dialog>
                        <!----- NOTE ----->
                        <v-dialog v-model="showInterveneNoteDialog" max-width="800">
                            <v-card>
                                <v-row justify="center"><v-card-actions class="mt-4"></v-card-actions></v-row>
                                <v-row justify="end" class="ma-2">
                                    <v-card-actions>
                                        <v-btn text @click="closeDialog()">
                                            <v-icon>
                                                mdi-close
                                            </v-icon>
                                        </v-btn>
                                    </v-card-actions>
                                </v-row>
                                <v-row>
                                    <v-col>
                                        <v-card flat class="pa-4 mb-4">
                                            <v-row>
                                                <v-col v-if="selectedTransaction != null">
                                                    <v-card-text>
                                                        <div class="font-weight-bold">{{ selectedTransaction.seller.firstName }}, {{ selectedTransaction.seller.lastName }}</div>
                                                        <div>{{ selectedTransaction.seller.email }}</div>
                                                    </v-card-text> 
                                                </v-col>
                                                <v-col v-if="selectedTransaction != null">
                                                    <v-card-text>
                                                        <div class="font-weight-bold">{{ selectedTransaction.buyer.firstName }}, {{ selectedTransaction.buyer.lastName }}</div>
                                                        <div>{{ selectedTransaction.buyer.email }}</div>
                                                        <div>{{ selectedTransaction.buyer.postalAddress.street }} {{ selectedTransaction.buyer.postalAddress.houseNumber }} {{ selectedTransaction.buyer.postalAddress.additionHouseNumber }}</div>
                                                        <div>{{ selectedTransaction.buyer.postalAddress.postalCode }} {{ selectedTransaction.buyer.postalAddress.city }}</div>
                                                    </v-card-text>
                                                </v-col>
                                            </v-row>
                                            <v-row class="my-0" v-if="selectedTransaction != null && selectedTransaction.intervene != null">
                                                <v-col>
                                                    <v-card-text class="py-0"> 
                                                        ISBN: {{ selectedTransaction.book.publishedBook.isbn }} 
                                                    </v-card-text>
                                                    <v-card-text class="py-0"> 
                                                        {{ selectedTransaction.book.publishedBook.title }} 
                                                    </v-card-text>
                                                    <v-card-text class="py-0">
                                                        Datum: {{ getFriendlyDate(selectedTransaction.date) }} {{ getFriendlyTime(selectedTransaction.date) }}
                                                    </v-card-text>
                                                    <v-card-text class="pb-0">
                                                        {{ trackAndTrace }}
                                                    </v-card-text>
                                                </v-col>
                                                <v-col>
                                                    <v-card-text class="py-0">
                                                        Gekocht: {{ parseFloat(selectedTransaction.intervene.boughtFor).toFixed(2) }}
                                                    </v-card-text>
                                                    <v-card-text class="py-0">
                                                        Verkocht: {{ parseFloat(soldPrice).toFixed(2) }}
                                                    </v-card-text>
                                                    <v-card-text class="py-0">
                                                        Winst: €{{ (parseFloat(selectedTransaction.intervene.soldFor) - parseFloat(selectedTransaction.intervene.boughtFor)).toFixed(2) }}
                                                    </v-card-text>
                                                </v-col>
                                            </v-row>
                                            <v-row>
                                                <v-col cols="12">
                                                    <v-textarea v-model="note" outlined placeholder="Motivatie"></v-textarea>
                                                </v-col>
                                                <v-col cols="12">
                                                    <v-row justify="end" class="mr-2">
                                                        <v-btn class="mr-4" @click="showInterveneNoteDialog = false">Annuleren</v-btn>
                                                        <v-btn color="primary" @click="onSendNoteMessage()"><v-icon>mdi-send</v-icon></v-btn>
                                                    </v-row>
                                                </v-col>
                                            </v-row>
                                            
                                        </v-card>
                                    </v-col>
                                </v-row>
                            </v-card>
                        </v-dialog>
                     </v-row>
                     <v-row class="white mt-6 mx-0">
                        <v-col cols="12" class="py-0">
                            <v-row class="mx-4 mt-4">
                                <v-text-field class="px-4" label="Order nummer, ISBN, naam koper/verkoper" v-model="searchInput"></v-text-field>
                            </v-row>
                        </v-col>
                        <v-col cols="12" class="pb-0">
                            <v-row class="mx-0">
                                <v-col cols="12" md="3">
                                    <v-checkbox
                                        class="px-4"
                                        dense
                                        v-model="searchInMyIntervenes"
                                        label="Mijn intervenes">
                                    </v-checkbox>
                                </v-col>
                                <v-col cols="12" md="6">
                                    <v-checkbox
                                        class="px-4"
                                        dense
                                        v-model="searchInBoekshareAccounts"
                                        label="Boekshare accounts">
                                    </v-checkbox>
                                </v-col>
                            </v-row>
                        </v-col>
                        <v-col cols="12" class="my-0">
                            <v-row class="mx-4 py-0">
                                <v-col cols="12" md="3">
                                    <v-menu
                                        v-model="menuDateFrom"
                                        :close-on-content-click="true"
                                        transition="scale-transition"
                                        offset-y
                                        min-width="auto">
                                        <template v-slot:activator="{ on }">
                                            <v-text-field
                                                class="pt-2" 
                                                v-model="dateFrom"
                                                label="Vanaf"
                                                prepend-icon="mdi-calendar"
                                                readonly
                                                outlined
                                                dense
                                                v-on="on"
                                            ></v-text-field>
                                        </template>
                                        <v-date-picker
                                            v-model="dateFrom" 
                                            no-title 
                                            dense
                                            scrollable>
                                        </v-date-picker>
                                    </v-menu>
                                </v-col>
                                <v-col cols="12" md="3">
                                    <v-menu
                                        v-model="menuDateTo"
                                        :close-on-content-click="true"
                                        transition="scale-transition"
                                        offset-y
                                        min-width="auto">
                                        <template v-slot:activator="{ on }">
                                            <v-text-field
                                                class="pt-2" 
                                                v-model="dateTo"
                                                label="tot"
                                                prepend-icon="mdi-calendar"
                                                readonly
                                                outlined
                                                dense
                                                v-on="on"
                                            ></v-text-field>
                                        </template>
                                        <v-date-picker
                                            v-model="dateTo" 
                                            no-title 
                                            dense
                                            scrollable>
                                        </v-date-picker>
                                    </v-menu>
                                </v-col>
                                <v-col cols="12" md="3" class="mt-2">
                                    <v-select
                                        :items="interveneStates"
                                        v-model="selectedInterveneState"
                                        :item-text="'value'"
                                        :item-value="'id'"
                                        label="Overname status"
                                        dense
                                        outlined>
                                    </v-select>
                                </v-col>
                                <v-col cols="12" md="3" class="mt-2">
                                    <v-select
                                        :items="transactionStates"
                                        v-model="selectedTransactionState"
                                        :item-text="'value'"
                                        :item-value="'id'"
                                        label="Transactie status"
                                        dense
                                        outlined>
                                    </v-select>
                                </v-col>
                            </v-row>
                        </v-col>
                    </v-row>
                    <v-row justify="end" class="pa-4">
                        <v-btn color="primary" @click="search()">ZOEKEN</v-btn>
                    </v-row>
                    <v-row>
                         <v-col>
                            <v-card :color="getColorForInterveneDays(transaction)" class="pa-4 mb-4" v-for="(transaction, index) in transactions" :key="index">
                                    <v-row v-if="transaction.state === 'rejected'" class="py-4 mx-n4 mt-n4 red accent-1 justify-center white--text">AFGEWEZEN</v-row>
                                    <v-row v-if="transaction.state === 'approved'" class="py-4 mx-n4 mt-n4 green green-2 white--text justify-center">GOEDGEKEURD</v-row>
                                    <v-row v-if="transaction.state !== 'approved' && transaction.state !== 'rejected'" class="py-4 mx-n4 mt-n4 grey grey-2 white--text justify-center">{{ getTransactionText(transaction.state) }}</v-row>
                                    <v-row>
                                        <v-col class="py-0" cols="12" md="2">
                                            <v-card-text>
                                                <div class="font-weight-bold">Order: {{ transaction.id }}</div>
                                            </v-card-text> 
                                        </v-col>
                                        <v-col class="py-0" cols="12" md="4">
                                            <v-card-text>
                                                <div class="font-weight-bold" v-if="transaction.intervene != null && 
                                                    transaction.intervene.intervener != null &&
                                                    transaction.intervene.history != null && 
                                                    transaction.intervene.history.length >0 && 
                                                    transaction.intervene.history[0].valueChangedTo !== 'Ingetrokken'">
                                                        <v-icon>mdi-clipboard-account</v-icon>: {{ transaction.intervene.intervener.firstName }} {{ transaction.intervene.intervener.lastName }}
                                                        <v-btn class="mb-1" text height="20px" width="20px" min-width="auto" :loading="loadingUnAssignUser && transaction.intervene.id === selectedId" @click="unassignUser(transaction.intervene)"><v-icon color="red" dense>mdi-close</v-icon></v-btn>
                                                </div>
                                            </v-card-text> 
                                        </v-col>
                                        <v-col class="py-0" cols="12" md="3">
                                            <v-card-text>
                                                <div class="font-weight-bold"><v-icon>mdi-alpha-s-box-outline</v-icon> {{ transaction.seller.firstName }}, {{ transaction.seller.lastName }}</div>
                                                <div>{{ transaction.seller.email }}</div>
                                            </v-card-text> 
                                        </v-col>
                                        <v-col class="py-0" cols="12" md="3">
                                            <v-card-text>
                                                <div class="font-weight-bold"><v-icon>mdi-alpha-b-box-outline</v-icon> {{ transaction.buyer.firstName }}, {{ transaction.buyer.lastName }}</div>
                                                <div>{{ transaction.buyer.email }}</div>
                                                <div>{{ transaction.buyer.postalAddress.street }} {{ transaction.buyer.postalAddress.houseNumber }} {{ transaction.buyer.postalAddress.additionHouseNumber }}</div>
                                                <div>{{ transaction.buyer.postalAddress.postalCode }} {{ transaction.buyer.postalAddress.city }}</div>
                                            </v-card-text>
                                        </v-col>
                                    </v-row>
                                    <v-row>
                                        <v-col class="py-0">
                                            <v-card-text class="pt-0">
                                                <div class="font-weight-bold">{{ getCurrentState(transaction.intervene) }}</div>
                                            </v-card-text>
                                        </v-col>
                                    </v-row>
                                    <v-row class="my-0">
                                        <v-col>
                                            <v-card-text class="pt-0"> 
                                                <v-img max-width="100"
                                                    :src="transaction.book.publishedBook.imageFileName">
                                                </v-img>
                                            </v-card-text>
                                            <v-card-text class="py-0"> 
                                                ISBN: <a :href="apiUrl + '/publishedbookoverview?publishedbook=' + transaction.book.publishedBook.id">{{ transaction.book.publishedBook.isbn }}</a>
                                            </v-card-text>
                                            <v-card-text class="py-0"> 
                                                Druk: {{ transaction.book.publishedBook.edition }} 
                                            </v-card-text>
                                            <v-card-text class="py-0"> 
                                                {{ transaction.book.publishedBook.title }} 
                                            </v-card-text>
                                            <v-card-text class="py-0">
                                                Geupload op: {{ getFriendlyDate(transaction.book.insertDate) }} {{ getFriendlyTime(transaction.book.insertDate) }}
                                            </v-card-text>
                                            <v-card-text class="py-0">
                                                Verkocht op: {{ getFriendlyDate(transaction.date) }} {{ getFriendlyTime(transaction.date) }}
                                            </v-card-text>
                                        </v-col>
                                        <v-col>
                                            <v-card-text class="py-0" v-if="transaction.intervene != null">
                                                Gekocht: €{{ parseFloat(transaction.intervene.boughtFor).toFixed(2) }}
                                            </v-card-text>
                                            <v-card-text class="py-0">
                                                Verkocht: €{{ parseFloat(transaction.amount).toFixed(2) }}
                                            </v-card-text>
                                            <v-card-text class="py-0" v-if="transaction.intervene != null && transaction.intervene.platform != null">
                                                Platform: {{ findPlatform(transaction.intervene.platform).value }}
                                            </v-card-text>
                                            <v-card-text class="py-0" v-if="transaction.intervene != null && transaction.intervene.boughtFor > 0.0">
                                                Winst: €{{ parseFloat(transaction.amount - transaction.intervene.boughtFor).toFixed(2) }}
                                            </v-card-text>
                                            <v-card-text class="pb-0" 
                                                        v-if="transaction.intervene != null 
                                                        && (transaction.intervene.currentState === 1 || transaction.intervene.currentState === 2)">
                                                <v-btn small :loading="loadingFindingBooks" color="secondary" 
                                                @click="searchBookToIntervene(transaction)" class="black--text">
                                                    zoeken
                                                </v-btn>
                                                <v-row v-if="transaction && transaction.foundBooks 
                                                && transaction.foundBooks.totalResultCount !== null 
                                                && transaction.foundBooks.totalResultCount !== undefined">
                                                    <v-col class="pb-0 pt-4" cols="12">
                                                        <a :href="'https://www.marktplaats.nl/l/boeken/q/' + transaction.book.publishedBook.isbn" target="_blank">Marktplaats</a>
                                                    </v-col>
                                                    <v-col class="py-0" cols="12">
                                                        {{ transaction.foundBooks.totalResultCount }} boek(en) gevonden op Marktplaats.
                                                    </v-col>

                                                    <!-- Check if listings exist or are empty -->
                                                    <v-col class="my-0" cols="12" v-if="!transaction.foundBooks.listings || transaction.foundBooks.listings.length === 0">
                                                        Helaas zijn er geen boeken gevonden op Marktplaats
                                                    </v-col>

                                                    <!-- Display listings if present -->
                                                    <v-col class="pt-0" cols="12" v-for="(item, i) in transaction.foundBooks.listings" :key="i">
                                                        {{ item.sellerInformation.sellerName }}<br />
                                                        €{{ (item.priceInfo.priceCents / 100.0).toFixed(2) }} {{ item.priceInfo.priceType }} <br />
                                                        --------
                                                    </v-col>
                                                </v-row>
                                            </v-card-text>
                                            <v-card-text class="py-0" v-if="transaction.intervene != null && transaction.intervene.barcode != null">
                                                <a v-if="transaction.intervene.barcode" :href="transaction.intervene.barcode" target="_blank">Track and trace</a>
                                            </v-card-text>
                                            <v-card-text class="py-0" v-if="transaction.intervene != null && transaction.intervene.url != null">
                                                <a v-if="transaction.intervene.url" :href="transaction.intervene.url" target="_blank">Betaallink</a>
                                            </v-card-text>
                                        </v-col>
                                    </v-row>
                                    <v-row>
                                        <v-col>
                                            <v-btn v-if="transaction.intervene != null" 
                                            color="secondary" 
                                            class="black--text" 
                                            @click="openBooksOfBoekShareAccount(transaction)">
                                                Verkoop boek
                                            </v-btn>
                                        </v-col>
                                    </v-row>
                                    <v-row>
                                        <v-col>
                                            <v-expansion-panels v-if="transaction.intervene != null 
                                            && transaction.intervene.addedBooksHistory != null 
                                            && transaction.intervene.addedBooksHistory.length > 0">
                                                <v-expansion-panel>
                                                    <v-expansion-panel-header>
                                                        Toegevoegde Boeken
                                                    </v-expansion-panel-header>
                                                    <v-expansion-panel-content>
                                                        <v-row class="secondary">
                                                            <v-col>Ingevoerd</v-col>
                                                            <v-col>Prijs</v-col>
                                                            <v-col>Beschrijving</v-col>
                                                            <v-col>Toegewezen aan verkoper</v-col>
                                                            <v-col>Toegevoegd door</v-col>
                                                        </v-row>
                                                        <v-row v-for="(addedBook, index) in transaction.intervene.addedBooksHistory" :key="index">
                                                            <v-col>
                                                                {{ getFriendlyDate(addedBook.inserted) }} {{ getFriendlyTime(addedBook.inserted) }}
                                                            </v-col>
                                                            <v-col>
                                                                €{{ addedBook.price }}
                                                            </v-col>
                                                            <v-col>
                                                                {{ addedBook.description }}
                                                            </v-col>
                                                            <v-col>
                                                                {{ addedBook.assignedToSeller.firstName }} {{ addedBook.assignedToSeller.lastName }}
                                                            </v-col>
                                                            <v-col>
                                                                {{ addedBook.studentAddedBook.firstName }} {{ addedBook.studentAddedBook.lastName }}
                                                            </v-col>
                                                        </v-row>
                                                    </v-expansion-panel-content>
                                                </v-expansion-panel>
                                            </v-expansion-panels>
                                            <v-expansion-panels class="mt-4" v-if="transaction.intervene != null 
                                                                                   && transaction.intervene.messages != null 
                                                                                   && transaction.intervene.messages.length > 0">
                                                <v-expansion-panel>
                                                    <v-expansion-panel-header>
                                                        Berichten
                                                    </v-expansion-panel-header>
                                                    <v-expansion-panel-content>
                                                        <v-row class="secondary">
                                                            <v-col>DATUM</v-col>
                                                            <v-col>BERICHT</v-col>
                                                            <v-col>GEBRUIKER</v-col>
                                                        </v-row>
                                                        <v-row v-for="(message, index) in transaction.intervene.messages" :key="index">
                                                            <v-col>
                                                                {{ getFriendlyDate(message.sent) }} {{ getFriendlyTime(message.sent) }}
                                                            </v-col>
                                                            <v-col>
                                                                {{ message.message }}
                                                            </v-col>
                                                            <v-col>
                                                                {{ message.userFullName }}
                                                            </v-col>
                                                        </v-row>
                                                    </v-expansion-panel-content>
                                                </v-expansion-panel>
                                            </v-expansion-panels>
                                            <v-expansion-panels class="mt-4" v-if="transaction.intervene != null 
                                                                                   && transaction.intervene.notes != null 
                                                                                   && transaction.intervene.notes.length > 0">
                                                <v-expansion-panel>
                                                    <v-expansion-panel-header>
                                                        Notities
                                                    </v-expansion-panel-header>
                                                    <v-expansion-panel-content>
                                                        <v-row class="secondary">
                                                            <v-col>DATUM</v-col>
                                                            <v-col>BERICHT</v-col>
                                                            <v-col>GEBRUIKER</v-col>
                                                        </v-row>
                                                        <v-row v-for="(note, index) in transaction.intervene.notes" :key="index">
                                                            <v-col>
                                                                {{ getFriendlyDate(note.created) }} {{ getFriendlyTime(note.created) }}
                                                            </v-col>
                                                            <v-col>
                                                                {{ note.message }}
                                                            </v-col>
                                                            <v-col>
                                                                {{ note.userFullName }}
                                                            </v-col>
                                                        </v-row>
                                                    </v-expansion-panel-content>
                                                </v-expansion-panel>
                                            </v-expansion-panels>
                                        </v-col>
                                    </v-row>
                                    <v-row>
                                        <v-col>
                                            <v-expansion-panels v-if="transaction.intervene != null 
                                            && transaction.intervene.history != null 
                                            && transaction.intervene.history.length > 0">
                                                <v-expansion-panel>
                                                    <v-expansion-panel-header>
                                                        Geschiedenis
                                                    </v-expansion-panel-header>
                                                    <v-expansion-panel-content>
                                                        <v-row class="secondary">
                                                            <v-col>DATUM</v-col>
                                                            <v-col>VELD</v-col>
                                                            <v-col>WAS</v-col>
                                                            <v-col>IS</v-col>
                                                            <v-col>AANGEPAST</v-col>
                                                        </v-row>
                                                        <v-row v-for="(history, index) in transaction.intervene.history" :key="index">
                                                            <v-col>
                                                                {{ getFriendlyDate(history.update) }} {{ getFriendlyTime(history.update) }}
                                                            </v-col>
                                                            <v-col>
                                                                {{ history.fieldText }}
                                                            </v-col>
                                                            <v-col>
                                                                <div v-if="history.fieldText === 'Platform'">
                                                                    {{ findPlatform(history.valueChangedFrom).value }}
                                                                </div>
                                                                <div v-if="history.fieldText == 'Ingekocht'">
                                                                    €{{ parseFloat(history.valueChangedFrom).toFixed(2) }}
                                                                </div>
                                                                <div v-if="history.fieldText == 'Winst'">
                                                                    €{{ parseFloat(history.valueChangedFrom).toFixed(2) }}
                                                                </div>
                                                                <div v-if="history.fieldText == 'Verzendlabel'">
                                                                    {{ history.valueChangedFrom }}
                                                                </div>
                                                                <div v-if="history.fieldText === 'Betaallink' 
                                                                || history.fieldText === 'Status' 
                                                                || history.fieldText === 'Toewijzen'">
                                                                    {{ history.valueChangedFrom }}
                                                                </div>
                                                            </v-col>
                                                            <v-col>
                                                                <div v-if="history.fieldText === 'Platform'">
                                                                    {{ findPlatform(history.valueChangedTo).value }}
                                                                </div>
                                                                <div v-if="history.fieldText == 'Ingekocht'">
                                                                    €{{ parseFloat(history.valueChangedTo).toFixed(2) }}
                                                                </div>
                                                                <div v-if="history.fieldText == 'Winst'">
                                                                    €{{ parseFloat(history.valueChangedTo).toFixed(2) }}
                                                                </div>
                                                                <div v-if="history.fieldText == 'Verzendlabel'">
                                                                    {{ history.valueChangedTo }}
                                                                </div>
                                                                <div v-if="history.fieldText === 'Betaallink' 
                                                                    || history.fieldText === 'Status'
                                                                    || history.fieldText === 'Toewijzen'">
                                                                    {{ history.valueChangedTo }}
                                                                </div>
                                                            </v-col>
                                                            <v-col>
                                                                {{ history.userFullName }}
                                                            </v-col>
                                                        </v-row>
                                                    </v-expansion-panel-content>
                                                </v-expansion-panel>
                                            </v-expansion-panels>
                                            <v-expansion-panels class="mt-4" v-if="transaction.intervene != null 
                                                                                   && transaction.intervene.messages != null 
                                                                                   && transaction.intervene.messages.length > 0">
                                                <v-expansion-panel>
                                                    <v-expansion-panel-header>
                                                        Berichten
                                                    </v-expansion-panel-header>
                                                    <v-expansion-panel-content>
                                                        <v-row class="secondary">
                                                            <v-col>DATUM</v-col>
                                                            <v-col>BERICHT</v-col>
                                                            <v-col>GEBRUIKER</v-col>
                                                        </v-row>
                                                        <v-row v-for="(message, index) in transaction.intervene.messages" :key="index">
                                                            <v-col>
                                                                {{ getFriendlyDate(message.sent) }} {{ getFriendlyTime(message.sent) }}
                                                            </v-col>
                                                            <v-col>
                                                                {{ message.message }}
                                                            </v-col>
                                                            <v-col>
                                                                {{ message.userFullName }}
                                                            </v-col>
                                                        </v-row>
                                                    </v-expansion-panel-content>
                                                </v-expansion-panel>
                                            </v-expansion-panels>
                                            <v-expansion-panels class="mt-4" v-if="transaction.intervene != null 
                                                                                   && transaction.intervene.notes != null 
                                                                                   && transaction.intervene.notes.length > 0">
                                                <v-expansion-panel>
                                                    <v-expansion-panel-header>
                                                        Notities
                                                    </v-expansion-panel-header>
                                                    <v-expansion-panel-content>
                                                        <v-row class="secondary">
                                                            <v-col>DATUM</v-col>
                                                            <v-col>BERICHT</v-col>
                                                            <v-col>GEBRUIKER</v-col>
                                                        </v-row>
                                                        <v-row v-for="(note, index) in transaction.intervene.notes" :key="index">
                                                            <v-col>
                                                                {{ getFriendlyDate(note.created) }} {{ getFriendlyTime(note.created) }}
                                                            </v-col>
                                                            <v-col>
                                                                {{ note.message }}
                                                            </v-col>
                                                            <v-col>
                                                                {{ note.userFullName }}
                                                            </v-col>
                                                        </v-row>
                                                    </v-expansion-panel-content>
                                                </v-expansion-panel>
                                            </v-expansion-panels>
                                        </v-col>
                                    </v-row>
                                    <v-row>
                                        <!-- { Open, Assigned, Parked, Ignore, Lost, InProgress, Finished, Cancelled } -->
                                        <v-col cols="12" md="6">
                                            <v-btn :disabled="transaction.intervene != null && transaction.intervene.currentState === 3" class="mt-2 mr-2" 
                                                @click="changeTransactionState(transaction, '3')">
                                                <v-icon>mdi-eye-remove-outline</v-icon> 
                                            </v-btn>
                                            <v-btn class="black--text mt-2 mr-2" @click="openInterveneNoteWindow(transaction)">
                                                <v-icon>mdi-note-edit-outline</v-icon>
                                            </v-btn>
                                            <v-btn class="black--text mt-2 mr-2" @click="interveneSelectedTransaction(transaction)">
                                                <v-icon>mdi-send</v-icon>
                                            </v-btn>
                                            <v-btn class="mr-4 mt-2 black--text" @click="openSelectedTransaction(transaction)">
                                                <v-icon>mdi-book-edit-outline</v-icon>
                                            </v-btn>
                                            <v-btn class="mr-4 ml mt-2 black--text" :disabled="transaction.intervene != null && transaction.intervene.currentState === 1"
                                                    @click="changeTransactionState(transaction, '1')">
                                                <v-icon>mdi-clipboard-account</v-icon>
                                            </v-btn>
                                        </v-col>
                                        <v-col cols="12" md="6" v-if="transaction.intervene != null">
                                            <v-btn :disabled="transaction.intervene != null 
                                                    && transaction.intervene.currentState === 2" 
                                                    class="mr-2 mt-2 " @click="changeTransactionState(transaction, '2')">
                                                <v-icon>mdi-car-brake-parking</v-icon>
                                            </v-btn>
                                            <v-btn :disabled="transaction.intervene != null 
                                                    && transaction.intervene.currentState === 7" 
                                                    class="mr-2 mt-2 " v-if="transaction.intervene != null" 
                                                    @click="changeTransactionState(transaction, '7')">
                                                <v-icon>mdi-cancel</v-icon>
                                            </v-btn>
                                            <v-btn :disabled="transaction.intervene != null 
                                                    && transaction.intervene.currentState === 4" 
                                                    class="mr-2 mt-2 " 
                                                    @click="changeTransactionState(transaction, '4')">
                                                <v-icon>mdi-package-variant-remove</v-icon>
                                            </v-btn>
                                            <v-btn :disabled="transaction.intervene != null 
                                                    && transaction.intervene.currentState === 5" 
                                                    class="mr-2 mt-2 " 
                                                    v-if="transaction.intervene != null" 
                                                    @click="changeTransactionState(transaction, '5')">
                                                <v-icon>mdi-currency-eur</v-icon>
                                            </v-btn>
                                            <v-btn :disabled="transaction.intervene != null 
                                                    && transaction.intervene.currentState === 6" 
                                                    class="mr-2 mt-2" 
                                                    v-if="transaction.intervene != null" 
                                                    @click="changeTransactionState(transaction, '6')">
                                                <v-icon>mdi-package-variant-closed-check</v-icon>
                                            </v-btn>
                                        </v-col>
                                    </v-row>
                                </v-card>
                         </v-col>
                    </v-row>
                    <v-row>
                        <v-col>
                            <v-row>
                                <v-pagination
                                    v-if="totalPages > 1"
                                    style="padding-bottom: 3%; padding-top: 3%"
                                    v-model="pageNumber"
                                    :length="totalPages"
                                    @input="loadTransactions(true)"
                                    :total-visible="7">
                                </v-pagination>
                            </v-row>
                        </v-col>
                    </v-row>
                 </v-col>
             </v-row>
         </v-container>
     </v-form>
 </template>
 
 <script>
import { mapGetters } from 'vuex';
import UserMenuCard from '../../../components/UserMenuCard.vue';
import TransactionService from '../../../services/TransactionService'
import InterveneService from '../../../services/InterveneService'
import BooksOfBoekShareAccount from '../../../components/admin/intervene/BooksOfBoekShareAccount.vue'

import { VueEditor } from 'vue2-editor';

   export default {
    data () {
       return {
        precentageDiscounts:[],
        discount: {},
        loading: false,
        selectedId: 0,
        loadingUnAssignUser: false,
        menuDiscountStartDate: false,
        menuDiscountEndDate: false,
        showInterveneDialog: false,
        showOpenDialog: false,
        showInterveneNoteDialog: false,
        selectedTransactionState: -1,
        selectedInterveneState: 0,
        boughtPrice: 0.00,
        soldPrice: 0.00,
        profit: 0.00,
        message: '',
        formattedMessage: '',
        searchInMyIntervenes: false, 
        searchInBoekshareAccounts: true,
        dialogBooksOfBoekShareAccount: false,
        trackAndTrace: '',
        pageNumber: 1,
        pageSize:6,
        totalPages:0,
        searchInput: '',
        transactions:[],
        dateFrom: this.getDateInAmsterdam(-14),
        dateTo: this.getDateInAmsterdam(0),
        selectedTransaction: null,
        url: '',
        platform: {},
        loadingFindingBooks: false,
        selectedTransactionToSearch: [],
        menuDateFrom: false,
        menuDateTo: false,
        note: '',
        selectedBookToSellAgain: null,
        apiUrl: process.env.VUE_APP_FE_URL,
        platforms: [
            {id:1, value:'Marktplaats'},
            {id:2, value:'Bol.com'},
            {id:3, value:'Boekshare'},
            {id:4, value:'BookMatch'},
            {id:5, value:'Anders'}
        ],
        interveneStates:[
            {id:-1, value:''},
            {id:0, value:'Open'},
            {id:1, value:'Toegewezen'},
            {id:2, value:'Geparkeerd'},
            {id:3, value:'Negeren'},
            {id:4, value:'Verloren'},
            {id:5, value:'Is betaald'},
            {id:6, value:'Is afgerond'},
            {id:7, value:'Geannuleerd'},
        ],
        transactionStates:[
            {id:-1, value:''},
            {id:0, value:'Open'},
            {id:1, value:'Bevestigd'},
            {id:2, value:'Betaald'},
            {id:3, value:'Geannuleerd'},
            {id:4, value:'Afgewezen'},
            {id:5, value:'Goedgekeurd'},
        ]
       }
    },

    components:{
        UserMenuCard,
        VueEditor,
        BooksOfBoekShareAccount
    }, 

    async created(){
        this.loading = true;
        await this.loadTransactions(true);
        this.message = await InterveneService.getMessage(this.getAccessToken(), 1);
        this.loading = false;
    },

    watch: {
        selectedTransaction: function(newTransaction) {
                if (newTransaction != null) {
                let replacedMessage = this.replacePlaceholders(this.message, newTransaction.buyer || {});
                replacedMessage = this.replacePlaceholders(replacedMessage, newTransaction.book.publishedBook || {});

                let sellingBooks = ' <a href=' 
                + process.env.VUE_APP_FE_URL + '/publishedbookoverview?publishedbook=' 
                + newTransaction.book.publishedBook.id + '>'  
                + newTransaction.book.publishedBook.title + '</a>';

                replacedMessage = this.replacePlaceholders(replacedMessage, {sellingBooks: sellingBooks} || {});
                this.formattedMessage = this.sanitizeHTML(replacedMessage);
            } else {
                this.formattedMessage = '';
            }
        },
    },
    
    methods:{
        ...mapGetters(['getAccessToken']),
        async search(){
            this.pageNumber = 1;
            await this.loadTransactions(true);
        },

        getTransactionText(state) {
            switch (state) {
                case 'rejected': return 'AFGEWEZEN';
                case 'approved': return 'GOEDGEKEUERD';
                case 'requested': return 'OPEN';
                case 'paid': return 'BETAALD';
                case 'finished': return 'BETAALD';
                default: return 'ONBEKENDE STATUS'; // Voor het geval dat een onbekende status wordt doorgegeven
            }
        },

        async dontHaveTheBook(transaction){
            try{
                this.loading = true;
                await TransactionService.rejectTransactionAdmin(this.getAccessToken(), transaction.id, 0);
                this.loading = false;
                await this.closeDialog();
            }
            catch{ }

            this.loading = false;
        },

        openBooksOfBoekShareAccount(transaction){
            this.selectedBookToSellAgain = transaction.book;
            this.selectedTransaction = transaction;
            this.dialogBooksOfBoekShareAccount = true;
        },

        async unassignUser(intervene){
            try{
                this.loadingUnAssignUser = true;
                this.selectedId = intervene.id;
                await InterveneService.unassignUser(this.getAccessToken(), intervene.id);
                await this.loadTransactions(false);
            }catch{}

            this.loadingUnAssignUser = false;
        },

        async isBookFound(transaction){
            let index = this.selectedTransactionToSearch.findIndex((item) => item.id === transaction.id);
            if(index === -1){
                return false;
            }
            return true;
        },

        async searchBookToIntervene(transaction){
            try{
                if(await this.isBookFound(transaction) === false){
                    this.loadingFindingBooks = true;
                    let result = await InterveneService.searchBookTontervene(this.getAccessToken(), transaction.book.publishedBook.id);
                    let foundBooks = result.foundBooks;
                    
                    // adding this property to the transaction
                    transaction.foundBooks = foundBooks;
                } 
            } catch{ 

            }

            this.loadingFindingBooks = false;
        },

        getDateInAmsterdam(daysToAdd = 0) {
            const today = new Date();
            today.setDate(today.getDate() + daysToAdd); // Adding/subtracting days

            const options = {
                year: 'numeric', month: '2-digit', day: '2-digit',
                timeZone: 'Europe/Amsterdam'
            };
            const dateFormatter = new Intl.DateTimeFormat('en-US', options);
            const formattedDate = dateFormatter.format(today);

            // The format() method returns the date in the format MM/DD/YYYY, we need to reorder to YYYY-MM-DD
            const [month, day, year] = formattedDate.split('/');
            return `${year}-${month}-${day}`;
        },

        async loadTransactions(scrollUp){
            try{
                this.loading = true;
                let result = await TransactionService.getTransactions(
                    this.getAccessToken(), 
                    this.pageNumber, 
                    this.pageSize,
                    this.selectedTransactionState, 
                    this.selectedInterveneState, 
                    this.searchInput,
                    this.dateFrom,
                    this.dateTo,
                    this.searchInMyIntervenes,
                    this.searchInBoekshareAccounts);

                this.transactions = result.transactions;
                this.totalPages = Math.ceil(result.totalCount /this.pageSize);
                this.loading = false;
                
                if(scrollUp){
                    window.scrollTo(0, 0);
                }
            }
            catch {  this.loading = false; }
        },

        getCurrentState(intervene){
            if(intervene!= null){
                switch(intervene?.currentState){
                    case 0: return "Open";
                    case 1: return "Toegewezen";
                    case 2: return "Geparkeerd";
                    case 3: return "Negeren";
                    case 4: return "Verloren";
                    case 5: return "Is betaald";
                    case 6: return "Is afgerond";
                    case 7: return "Geannuleerd";
                    default: return intervene?.currentState;
                }
            }else{
                return "Open"
            }
        },

        async changeTransactionState(transaction, state){
            await InterveneService.changeState(this.getAccessToken(), transaction.id, state);
            await this.loadTransactions(false);
        },

        async onSendMessage(){
            await InterveneService.sendMessage(
                this.getAccessToken(), 
                this.selectedTransaction.id, 
                this.selectedTransaction.intervene.id, 
                this.formattedMessage);

                this.showInterveneDialog = false;

                this.loading = true;
                await this.loadTransactions(false);
                this.loading = false;
        },

        async onSendNoteMessage(){
            let inteveneId = this.selectedTransaction.intervene == null ? -1 : this.selectedTransaction.intervene.id
            await InterveneService.sendNoteMessage(
                this.getAccessToken(),
                this.selectedTransaction.id, 
                inteveneId, 
                this.note);

                this.showInterveneNoteDialog = false;

                this.loading = true;
                await this.loadTransactions(false);
                this.loading = false;
        },

        async closeDialog(){
            this.showInterveneDialog = false;
            this.showOpenDialog = false;
            this.showInterveneNoteDialog = false;
            this.dialogBooksOfBoekShareAccount = false;
            this.loading = true;
            await this.loadTransactions(false);
            this.loading = false;
        },

        onBoughtPriceModified(){
            if(this.boughtPrice !== null && this.boughtPrice !== ''){
                this.profit = (parseFloat(parseFloat(this.selectedTransaction.intervene.soldFor) - this.boughtPrice)).toFixed(2);
            } else{
                this.profit = 0.0;
            }
        },

        async saveIntervene(){
            let id = 0;
            if(this.selectedTransaction.intervene != null){
                id = this.selectedTransaction.intervene.id;
            }

            let platformId = null;
            if(this.platform != null){
                if(this.platform.id != null){
                    platformId = this.platform.id;
                } else {
                    platformId = this.platform;
                }
                
            }

            let intervene = { 
                id: id,
                boughtFor: this.boughtPrice,
                soldFor: parseFloat(this.soldPrice),
                barcode: this.trackAndTrace,
                url: this.url,
                platform: platformId 
            }
            
            if(this.selectedTransaction!= null){
                await InterveneService.save(this.getAccessToken(), intervene, this.selectedTransaction.id);
            } else {
                await InterveneService.save(this.getAccessToken(), intervene, 0);
            }

            await this.closeDialog();
        },

        getFriendlyDate(val){
            var date = new Date(val);
            return date.getDate()  + "-" + (date.getMonth()+1) + "-" + date.getFullYear();
        },

        getFriendlyTime(val){
            var date = new Date(val);
            return date.getHours() + ":" + String(date.getMinutes()).padStart(2, "0");
        },

        replacePlaceholders(template, replacements) {
            return template.replace(/{(\w+)}/g, (match, key) => {
                const value = replacements[key];
                return typeof value === 'string' ? value : match;
            });
        },


        sanitizeHTML(html) {
            // Verwijder alle <p> tags maar behoud de innerHTML
            return html.replace(/<\/?p>/g, '');
        },
        
        findPlatform(id){
            const platform = this.platforms.find(element => element.id == id);
            return platform || {id: 99, value: "Niet bekend"};
        },       
        
        getColorForInterveneDays(transaction) {
            const difference = new Date() - new Date(transaction.date);
            const days = Math.floor(difference / (1000 * 60 * 60 * 24));
            if(days >= 7 && days <= 10){
                return '#FFF9C4';
            } else if (days > 10){
                return '#FFCDD2';
            } else{
                return '';
            }
        },

        openSelectedTransaction(transaction){
            this.showOpenDialog = true; 
            this.selectedTransaction = transaction;
            this.soldPrice = parseFloat(transaction.amount).toFixed(2); 

            if(transaction.intervene != null){
                this.boughtPrice = transaction.intervene.boughtFor;
                this.trackAndTrace = transaction.intervene.barcode;
                this.url = transaction.intervene.url;
                this.soldPrice = parseFloat(transaction.intervene.soldFor).toFixed(2);
                this.profit = (parseFloat(this.selectedTransaction.intervene.soldFor) - parseFloat(transaction.intervene.boughtFor)).toFixed(2);
                this.platform = this.platforms.find(element => {
                    if(element.id == transaction.intervene.platform){
                        return true;
                    }
                });
            } else {
                this.boughtPrice = 0.00;
                this.profit = 0.00;
            }
        },

        openInterveneNoteWindow(transaction){
            this.showInterveneNoteDialog = true;
            this.selectedTransaction = transaction;
            this.soldPrice = parseFloat(transaction.amount).toFixed(2);
        },

        interveneSelectedTransaction(transaction){
            this.showInterveneDialog = true; 
            this.selectedTransaction = transaction;

            this.soldPrice = parseFloat(transaction.amount).toFixed(2);
            let replacedMessage = this.replacePlaceholders(this.message, transaction.buyer || {});
            replacedMessage = this.replacePlaceholders(replacedMessage, transaction.book.publishedBook || {});
            let sellingBooks = '<a href=' + process.env.VUE_APP_FE_URL + '/publishedbookoverview?publishedbook=' + transaction.book.publishedBook.id + '>'  + transaction.book.publishedBook.title + '</a>';
            replacedMessage= this.replacePlaceholders(replacedMessage, {sellingBooks: sellingBooks} || {});
            this.formattedMessage = this.sanitizeHTML(replacedMessage);

            if(transaction.intervene != null){
                this.boughtPrice = transaction.intervene.boughtPrice;
            } else {
                this.boughtPrice = 0.00;
                this.profit = 0.00;
            }
        }
    }
}
 </script>
 