import axios from "../plugins/axios";

const baseUrl = `${process.env.VUE_APP_BOOKSHARE_URL}/books/admin/`;

export default {

    async getBooksOfStudentAsAdmin(token, userId, pageNumber, pageSize, sellingBooksOnly) {
        let result;
        const config = {
            headers: { Authorization: `Bearer ${token}` }
        };
        let data = {
          "userId": userId,
          "pageNumber": pageNumber,
          "pageSize": pageSize,
          "sellingBooksOnly": sellingBooksOnly
        }
        await axios.post(`${baseUrl}get-books-of-student`, data, config)
                .then(response =>
                  result = response.data);
        return result;
    },

    async getBooksOverview(token, pageNumber, pageSize) {      
        let booksResponse;
        const config = {
            headers: { Authorization: `Bearer ${token}` }
        };
  
        let data = {
          "pageNumber":  pageNumber,
          "pageSize":  pageSize,
        }
  
        await axios.post(`${baseUrl}booksoverview`, data, config)
                .then(response =>
                  booksResponse = response.data);
  
        return booksResponse;
    },

    async addBook(token, userId, book, interveneId) {
        if (typeof book.price === 'string') {
            if(book.price.includes(',')){
                book.price = book.price.replace(',', '.');
            }
        }
        
        let result;
        const config = {
            headers: { Authorization: `Bearer ${token}` }
        };
        let data = {
          "book":  book,
          "userId": userId,
          "interveneId":interveneId,
        }
        await axios.post(`${baseUrl}add-book-to-student`, data, config)
                .then(response =>
                    result = response.data);
  
        return result;
    },

    async isStudentAlreadySellingBook(token, userId, publishedBookId) {
        let result;
        const config = {
            headers: { Authorization: `Bearer ${token}` }
        };
        let data = {
        "userId": userId,
        "publishedBookId": publishedBookId
        }
        await axios.post(`${baseUrl}is-student-already-selling-book`, data, config)
                .then(response =>
                result = response.data);
        return result;
    },
}